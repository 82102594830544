import { SET_IS_MEMOPAGE_CLOSED, SET_IS_MEMORIAL_SIDEBAR_RENDERED, SET_IS_MEMO_PAGE } from "./isrendered.actions"

const initialState = {
    isMemorialSidebarRendered: false,
    isMemoPage: null,
    isMemoPageClosed: false,
}

export const isRenderedReducer = ( state = initialState, action ) => {
    switch( action.type ) {
        case SET_IS_MEMORIAL_SIDEBAR_RENDERED:
            return {
                ...state,
                isMemorialSidebarRendered: action.payload.prop,
            }
        case SET_IS_MEMO_PAGE:
            return {
                ...state,
                isMemoPage: action.payload.prop,
            }
        case SET_IS_MEMOPAGE_CLOSED:
            return {
                ...state,
                isMemoPageClosed: action.payload.prop,
            }
        default: return state
    }
}