import { SET_SCROLL_COORDS } from "./onscroll.actions";

const initialState = {
    scrollCoords: 0,
}

export const onScrollReducer = ( state = initialState, action ) => {
    switch( action.type ) {
        case SET_SCROLL_COORDS:
            return {
                ...state,
                scrollCoords: action.payload.coords,
            }
        default: return state
    }
}