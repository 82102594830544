//Redux
import { useDispatch } from "react-redux"
import { set_new_notifys_list } from "../../../../../redux/Notifications/notifications.actions"

//Hooks
import { confirmNotify, declineNotify, markAllNotifysReaded } from "../../../../../hooks/useApi"
import { useLocalStorages } from "../../../../../hooks/useLocalStorages"
import { useProfileApi } from "../../../../Pages/ProfilePage/hooks/useProfileApi"
import { useHomeHeaderApi } from "../../../hooks/useHeaderApi"
import { renderSuccessModal } from "../../../../../redux/Modals/renderModals"

export const useHeaderNotifications = () => {
    const dispatch = useDispatch()
    const { setNotifysList } = useHomeHeaderApi()
    const { setMemorialsList } = useProfileApi()
    const { getItem } = useLocalStorages()

    const confirm = async ( id, setIsSended ) => {
        setIsSended(true)
        return await confirmNotify(id)
            .then( async res => {
                if ( res.status === 200 ) {
                    dispatch(renderSuccessModal(true, "Done, you are now co-author of this page."))
                    await setNotifysList()
                    await setMemorialsList(getItem("propKey"))
                    setIsSended(false)
                } 
            })
            .catch( err => dispatch(set_new_notifys_list(null)) )
    }
    
    const decline = async ( id, setIsSended ) => {
        setIsSended(true)
        return await declineNotify(id)
            .then( async res => {
                if ( res.status === 200 ) {
                    dispatch(renderSuccessModal(true, "We will inform the owner of the page about your decision."))
                    await setNotifysList()
                    await setMemorialsList(getItem("propKey"))
                    setIsSended(false)
                }
            })
            .catch( err => dispatch(set_new_notifys_list(null)) )
    }

    const clearNotifications = async setIsSended => {
        setIsSended(true)
        return await markAllNotifysReaded()
            .then( async res => {
                setIsSended(false)
                await setNotifysList()
            })
            .catch( err => dispatch(set_new_notifys_list(null)) )
    }

    return {
        confirm,
        decline,
        clearNotifications,
    }
}