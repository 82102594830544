//React
import React from "react"
import { Link } from "react-router-dom"
import "./FirstScreen.scss"

//Redux
import { connect } from "react-redux"
import { authSelector } from "../../../../redux/Auth/auth.selectors"

const FirstScreenContainer = ({ Auth }) => {
    return(
        <div className="containerBe firstScreen">
            <div className="firstScreen_title">
                Keep the memory
                <br />
                of your loved ones alive
            </div>
            <Link 
                to={Auth 
                        ? "/profile/new" 
                        : { pathname: "/sign_up" } 
                } 
                className="grey_link createEternalMemory"
            >Create an eternal memory</Link>
        </div>
    )
}

const mapState = state => {
    return {
        Auth: authSelector(state),
    }
}

export default connect(mapState)(FirstScreenContainer)