export const usePageGenerator = setIsLoading => {
    const generateConfig = ( title, prop, classes, pc ) => {
        const setLoader = timer => {
            setIsLoading(true)
            setTimeout(() => setIsLoading(false), timer)
        }

        if ( pc !== classes ) {
            setLoader(300)
        }

        return {
            pageTitle: title,
            isHomePage: prop,
            mainContainerClasses: classes,
            prevClasses: pc,
        }
    }

    const definePageConfig = ( title, setPageConfig ) => {
        const page = title.toLowerCase()
        const fc = "first_screen"
        const sc = "first_screen first_screen_page"

        if ( page === "home" ) {
            return setPageConfig(prev => generateConfig("", true, fc, prev.mainContainerClasses))
        }

        if ( page === "404" || page === "profile" ) {
            return setPageConfig(prev => generateConfig("", false, sc, prev.mainContainerClasses))
        }

        return setPageConfig(prev => generateConfig(title, false, sc, prev.mainContainerClasses))
    }

    return {
        definePageConfig,
    }
}