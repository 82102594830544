//react
import React, { useEffect, useState } from "react"
import moment from "moment"

//redux
import { connect } from "react-redux"
import { newNotifysListSelector } from "../../../../redux/Notifications/notifications.selectors"
import { useHeaderNotifications } from "./hooks/useHeaderNotifications"

const HeaderNotifications = props => {
    const [ isSended, setIsSended ] = useState(false)
    const { confirm, decline, clearNotifications } = useHeaderNotifications()

    const { notifysList } = props

    const notifyRedirecter = url => {
        if ( !url ) {
            goToPreview("/profile/notifications")
            return
        }

        if ( url ) {
            goToPreview(url)
            return
        }
    }

    const goToPreview = url => {
        const win = window.open(
            `${window.location.origin}${url}`, 
            "_blank"
        );
        win.focus();
      }

    const notifyClassesValidate = isViewed => {
        return !isViewed ? "notificationBtn-dropdownmenu-item notificationItem" : "notificationBtn-dropdownmenu-item notificationItem readed"
    }

    const midClassesValidate = url => {
        return url ? "notificationBtn-dropdownmenu-item-mid notifyWithLink" : "notificationBtn-dropdownmenu-item-mid"
    }

    const isViewedValidation = isViewed => {
        return isViewed ? "" : "Unreaded"
    }

    useEffect(() => {
        return () => {
            clearNotifications(setIsSended)
        }
    }, [])

    const validateDate = date => {
        return moment(date).format("DD.MM.YYYY") === moment().format("DD.MM.YYYY")
            ?  moment(date).fromNow()
            :  `${moment(date).format("DD.MM.YYYY")} at ${moment(date).format("hh:mm A")}`    
    }

    return(
        <React.Fragment>
            <div className="notificationBtn-container">
                <ul className="notificationBtn-dropdownmenu">
                    {notifysList &&
                            notifysList.sort( (a, b) => new Date(b.created) - new Date(a.created) ).sort( (a, b) => b.isViewed - a.isViewed ).map( notify => {
                                const { id, comment, created, forConfirm, isViewed, url } = notify
                                return(
                                    <li key={id} className={notifyClassesValidate(isViewed)}>
                                        <div className="notificationBtn-dropdownmenu-item-top">{isViewedValidation(isViewed)}</div>
                                        <div className={midClassesValidate(url)} onClick={() => notifyRedirecter(url)}>{comment}</div>
                                        <div className="notificationBtn-dropdownmenu-item-bottom">{validateDate(created)}</div>
                                        {forConfirm && 
                                            <div className="notificationBtn-dropdownmenu-item-navigation">
                                                <button disabled={isSended} className="notificationBtn-dropdownmenu-item-navigation-button notifyConfirm" onClick={() => confirm(id, setIsSended)}>Accept</button>
                                                <button disabled={isSended} className="notificationBtn-dropdownmenu-item-navigation-button notifyDecline" onClick={() => decline(id, setIsSended)}>Decline</button>
                                            </div>
                                        }
                                    </li>
                                )
                            })
                    }
                    {!notifysList && <li className="notificationBtn-noItem" onClick={() => notifyRedirecter()}>Notifys list is empty</li>}
                </ul>
            </div>
        </React.Fragment>
    )
}

const mapState = state => {
    return {
        notifysList: newNotifysListSelector(state)
    }
}

export default connect(mapState)(HeaderNotifications)