export const SET_IS_MEMORIAL_SIDEBAR_RENDERED = "MEMORIAL/SET_IS_MEMORIAL_SIDEBAR_RENDERED"
export const SET_IS_MEMO_PAGE = "MEMORIAL/SET_IS_MEMO_PAGE"
export const SET_IS_MEMOPAGE_CLOSED = "MEMORIALS/SET_IS_MEMOPAGE_CLOSED"

export const set_is_memorial_sidebar_rendered = prop => {
    return {
        type: SET_IS_MEMORIAL_SIDEBAR_RENDERED,
        payload: {
            prop,
        }
    }
}

export const set_is_memo_page = prop => {
    return {
        type: SET_IS_MEMO_PAGE,
        payload: {
            prop,
        }
    }
}

export const set_is_memopage_closed = prop => {
    return {
        type: SET_IS_MEMOPAGE_CLOSED,
        payload: {
            prop,
        }
    }
}