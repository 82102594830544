import React from "react";
import promodex from "../../../../../assets/images/promodex-logo.svg";

import styles from './FooterBottom.module.scss'

function FooterBottom() {
  return (
    <div className={styles.footerBottomContainer}>
      <p className={styles.copyright}>© 2021 Life Trace All rights reserved</p>
      <div className={styles.designBy}>
        {/* <p>Design by:</p>
        <img src={promodex} /> */}
      </div>
    </div>
  );
}

export default FooterBottom;
