export const isSuccessModalSelector = state => state.modal.isSuccessModal
export const isErrorModalSelector = state => state.modal.isErrorModal
export const isWarrningModalSelector = state => state.modal.isWarrningModal
export const isLogoutModalSelector = state => state.modal.isLogoutModal
export const isForConfirmModalSelector = state => state.modal.isForConfirmModal
export const isQRModalSelector = state => state.modal.isQRModal
export const isGalerySelector = state => state.modal.isGalery
export const isFriendModalSelector = state => state.modal.isFriendModal
export const isCookieSelector = state => state.modal.isCookie
export const isFilmModalSelector = state => state.modal.isFilmModal
export const isIniviteModalSelector = state => state.modal.isInviteModal
export const isToPaymentModalSelector = state => state.modal.isToPaymentModal
export const isFriendPopupModalSelector = state => state.modal.isFriendPopupModal
export const isToCreateMemoPageModalSelector = state => state.modal.isToCreateMemoPageModal
export const isAddPhotoToGaleryModalSelector = state => state.modal.isAddPhotoToGaleryModal
export const isToTributesModalSelector = state => state.modal.isToTributesModal
export const isProfileMenuSelector = state => state.modal.isProfileMenu
export const isReinviteModalShownSelector = state => state.modal.isReinviteModalShown
export const isFilesLoadingPopupSelector = state => state.modal.isFilesLoadingPopup
export const isModalWithCheckboxSelector = state => state.modal.isModalWithCheckbox