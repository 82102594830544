import { SET_ERROR_TEXT, SET_FOR_CONFIRM_TEXT, SET_SUCCESS_TEXT, SET_TO_PAYMENT_MODAL_TEXT, SET_WARRNING_TEXT, SET_MODAL_WITH_CHECKBOX_TEXT } from "./messageToModal.actions";

const initialState = {
    erroText: "",
    successText: "",
    forConfirmText: "",
    toPaymentModalText: "",
    modalWithCheckboxText: "",
}

export const messageToModalReducer = ( state = initialState, action ) => {
    switch(action.type) {
        case SET_ERROR_TEXT:
            return {
                ...state,
                errorText: action.payload.errorText
            }
        case SET_SUCCESS_TEXT:
            return {
                ...state,
                successText: action.payload.successText,
            }
        case SET_WARRNING_TEXT:
            return {
                ...state,
                warrningText: action.payload.warrningText,
            }
        case SET_FOR_CONFIRM_TEXT:
            return {
                ...state,
                forConfirmText: action.payload.text,
            }
        case SET_TO_PAYMENT_MODAL_TEXT:
            return {
                ...state,
                toPaymentModalText: action.payload.text,
            }
        case SET_MODAL_WITH_CHECKBOX_TEXT:
            return {
                ...state,
                modalWithCheckboxText: action.payload.text
            }
        default: return state
    }
}