export const SET_SIGN_UP_EMAIL = "SIGN_UP/SET_SIGN_UP_EMAIL"
export const SET_SIGN_UP_NAME = "SIGN_UP/SET_SIGN_UP_NAME"
export const SET_SIGN_UP_PASSWORD = "SIGN_UP/SET_SIGN_UP_PASSWORD"
export const SET_SIGN_UP_CONFIRM_PASSWORD = "SIGN_UP/SET_SIGN_UP_CONFIRM_PASSWORD"
export const SET_SUCCESS_SIGNED = "SIGN_UP/SET_SUCCESS_SIGNED"
export const SET_SERVER_ERROR_MESSAGE = "SIGN_UP/SET_SERVER_ERROR_MESSAGE"

export const set_sign_up_emai = email => {
    return {
        type: SET_SIGN_UP_EMAIL,
        payload: {
            email,
        }
    }
}

export const set_sign_up_name = name => {
    return {
        type: SET_SIGN_UP_NAME,
        payload: {
            name,
        }
    }
}

export const set_sign_up_password = password => {
    return {
        type: SET_SIGN_UP_PASSWORD,
        payload: {
            password,
        }
    }
}

export const set_sign_up_confirm_password = password => {
    return {
        type: SET_SIGN_UP_CONFIRM_PASSWORD,
        payload: {
            password,
        }
    }
}

export const set_success_signed = prop => {
    return {
        type: SET_SUCCESS_SIGNED,
        payload: {
            prop,
        }
    }
}