export const SET_NEW_NOTIFYS_LIST = "NOTIFICATIONS/SET_NEW_NOTIFYS_LIST"
export const SET_FILTERED_NOTIFYS_LIST = "NOTIFICATIONS/SET_FILTERED_NOTIFYS_LIST"
export const SET_IS_NOTIFY_ERROR = "NOTIFICATIONS/SET_IS_NOTIFY_ERROR"

export const set_new_notifys_list = list => {
    return {
        type: SET_NEW_NOTIFYS_LIST,
        payload: {
            list,
        }
    }
}

export const set_filtered_notifys_list = list => {
    return {
        type: SET_FILTERED_NOTIFYS_LIST,
        payload: {
            list,
        }
    }
}

export const set_is_notify_error = prop => {
    return {
        type: SET_IS_NOTIFY_ERROR,
        payload: {
            prop,
        }
    }
}