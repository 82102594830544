import React from "react";
import {Link} from 'react-router-dom'

import styles from './Navlink.module.scss'

function Navlink({ href, text, onClick }) {
  return <Link className={styles.navlink} to={href} onClick={onClick}>{text}</Link>;
}

export default Navlink;
