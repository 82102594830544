import React from "react";
import Address from "./components/Address";
import FooterBottom from "./components/FooterBottom";
import FooterLinks from "./components/FooterLinks/FooterLinks";
import FooterLogo from "./components/FooterLogo/FooterLogo";
import SocialIcons from "./components/SocialIcons";

import styles from './NewFooter.module.scss'

function NewFooter() {
  return (
    <div className={styles.newFooterMainContainer}>
      <div className={styles.top}>
        <div className={styles.logoAndSocial}>
          <FooterLogo />
          <SocialIcons />
        </div>
        <FooterLinks />
        <Address />
      </div>
      <div className={styles.bottom}>
        <FooterBottom />
      </div>
    </div>
  );
}

export default NewFooter;
