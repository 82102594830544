import { searchMemoPage } from "../../../hooks/useApi"
import { set_home_six_memopages } from "../../HomePage/homePage.action"

export const SET_MEMORIAL_SEARCH_NAME  = "SEARCHFORM/SET_SEARCH_NAME"
export const SET_MEMORIAL_SEARCH_BORN_DATE = "SEARCHFORM/SET_MEMORIAL_SEARCH_BORN_DATE"
export const SET_MEMORIAL_SEARCH_PASSED_AWAY_DATE = "SEACHFORM/SET_MEMORIAL_SEARCH_PASSED_AWAY_DATE"
export const SET_MEMORIAL_SEARCH_OCUPATION = "SEARCHFORM/SET_MEMORIAL_SEARCH_OCUPATION"

export const set_memorial_search_name = name => {
    return {
        type: SET_MEMORIAL_SEARCH_NAME,
        payload: {
            name,
        }
    }
}

export const set_memorial_search_ocupation = ocupation  => {
    return {
        type: SET_MEMORIAL_SEARCH_OCUPATION,
        payload: {
            ocupation,
        }
    }
}

export const set_memorial_search_born_date = date => {
    return {
        type: SET_MEMORIAL_SEARCH_BORN_DATE,
        payload: {
            date,
        }
    }
}

export const set_memorial_search_passed_away_date = date => {
    return {
        type: SET_MEMORIAL_SEARCH_PASSED_AWAY_DATE,
        payload: {
            date,
        }
    }
}

export const search_memopages = data => 
    async dispatch => {
        dispatch(set_home_six_memopages(null))
        await searchMemoPage(data)
            .then( res => {
                if (res.status === 200) {
                    dispatch(set_home_six_memopages(res.data))
                }
            })
            .catch(err => dispatch(set_home_six_memopages(null)))
    }