export const SET_USER_DATA = "USERDATA/SET_USER_DATA";
export const SET_PAYMENT_MEMORIAL_DATA = "USERDATA/SET_PAYMENT_MEMORIAL_DATA";
export const SET_USER_PROFILE = "USERDATA/SET_USER_PROFILE";

export const set_user_data = (userData) => {
  return {
    type: SET_USER_DATA,
    payload: {
      userData,
    },
  };
};

export const set_payment_memorial_data = (data) => {
  return {
    type: SET_PAYMENT_MEMORIAL_DATA,
    payload: {
      data,
    },
  };
};

export const set_user_profile = (userProfile) => {
  return {
    type: SET_USER_PROFILE,
    payload: {
      userProfile,
    },
  };
};
