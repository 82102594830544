import axios from 'axios';
import config from './config';

export const initAxios = () => {
    axios.defaults.baseURL = config.baseApiUrl;
    axios.defaults.headers.post['Content-Type'] = 'application/json';
    axios.interceptors.request.use(
        async function (config) {
            !config.headers.Accept && (config.headers.Accept = '*/*');
            let TOKEN = JSON.parse(localStorage.getItem('id_token'));
            if (TOKEN) {
                !config.headers['Authorization'] && (config.headers['Authorization'] = "Bearer " + TOKEN);
            }
            return config;
        },
        function (response) {
            return Promise.resolve(response);
        },
        function (error) {
            if (error.response && error.response.status === 401) {
                return Promise.reject(error);
            } else if (error.response && error.response.status === 400) {
                return Promise.reject(error);
            } else {
                return Promise.resolve(error);
            }
        }
    );
}