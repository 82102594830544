import { gapi } from "gapi-script";
import config from "./config";

export const googleAuthInit = () => {
  gapi.load("client:auth2", () => {
    gapi.client.init({
      clientId: config.googleClientId,
      plugin_name: "chat",
    });
  });
};
