import React from "react"
import "./UpButton.scss"
import ArrowUpwardIcon from '../../../assets/images/top-arrow-icon.svg'

const UpButton = () => {
    return(
        <div style={{ opacity: "85%" }} onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })} className="upArrow">
            <img src={ArrowUpwardIcon} className="arrow" />
        </div> 
    )
}

export default UpButton