//React
import React, { useEffect } from "react"
import "./LogoutModal.scss"

//img
import LogoutImg from "../../../assets/images/logout_img.png"

//Hooks
import { useLocalStorages } from "../../../hooks/useLocalStorages"

//Redux
import { connect } from "react-redux"
import { set_auth } from "../../../redux/Auth/auth.actions"
import { set_user_data } from "../../../redux/Profile/UserData/userdata.actions"
import { renderLogoutModal, renderSuccessModal } from "../../../redux/Modals/renderModals"
import { set_is_friend, set_is_friend_are_subsriber } from "../../../redux/MemorialsPage/MemorialFriedndsList/memorialfriends.actions"

const LogoutModal = ({ setAuth, closeModal, setUserData, successModal, setIsFriend, setIsFriendAreSubscriber }) => {
    const { clearLocalStorage } = useLocalStorages()

    const onLogout = () => {
        clearLocalStorage()
        setAuth(false)
        setUserData(null)
        setIsFriend(false)
        setIsFriendAreSubscriber(false)
        successModal(true, "Log Out Successfully")
        closeModal()
    }

    useEffect(() => {
        const onClick = e => {
            if ( e.keyCode === 27 ) {
                closeModal() 
            }
        }
        document.addEventListener("keydown", onClick)

        return () => document.removeEventListener("keydown", onClick)
    }, [])

    return(
        <div 
            className="logoutModal"
            id="logoutModal"
        >
            <div className="logoutModal-container">
                <div className="logoutModal-img">
                    <img src={LogoutImg} alt="logout Img" />
                </div>
                <div className="logoutModal-text">Are You Want to Log Out?</div>
                <div className="logoutModal-navigation">
                    <button className="logoutModal-navigation-btn logoutLogout" onClick={ () => onLogout() }>Logout</button>
                    <button className="logoutModal-navigation-btn logoutClose" onClick={ () => closeModal() }>Cancel</button>
                </div>
            </div>
        </div>
    )
}

const mapDispatch = {
    setAuth: prop => set_auth(prop),
    closeModal: () => renderLogoutModal(false),
    setUserData: data => set_user_data(data),
    successModal: prop => renderSuccessModal(prop),
    setIsFriend: prop => set_is_friend(prop),
    setIsFriendAreSubscriber: prop => set_is_friend_are_subsriber(prop),
}

export default connect(null, mapDispatch)(LogoutModal)