import React from "react";

import fb from "../../../../../assets/images/fb-logo.svg";
import insta from "../../../../../assets/images/insta-logo.svg";
import tg from "../../../../../assets/images/tg-logo.svg";
import twitter from "../../../../../assets/images/twitter-new.svg";

import styles from "./SocialIcons.module.scss";

const SOCIAL_ICONS = [
  { href: "https://www.facebook.com/lifetrace1", img: fb },
  { href: "https://instagram.com/lifetrace.online", img: insta },
  { href: "https://twitter.com/Lifetrace1", img: twitter },
];

function SocialIcons() {
  return (
    <div className={styles.socialContainer}>
      {SOCIAL_ICONS.map(({ href, img }) => (
        <a href={href} key={href} target="_blank" rel="noreferrer">
          <img src={img} />
        </a>
      ))}
    </div>
  );
}

export default SocialIcons;
