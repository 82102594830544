import { SET_CURRENT_PROFILE_PAGE } from "./profilePages.actions"
import { SET_PRODUCT } from "./profilePages.actions"

const initialState = {
    page: "",
    product: null
}

export const profilePagesReducer = ( state = initialState, action ) => {
    switch( action.type ) {
        case SET_CURRENT_PROFILE_PAGE:
            const { page } = action.payload;
            return {
                ...state,
                page: page,
            }
        case SET_PRODUCT:
            const { product } = action.payload;
            return {
                ...state,
                product
            }
        default: return state
    }
}