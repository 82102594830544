//Hooks
import { useLocalStorages } from "../../../../hooks/useLocalStorages"
import { getMemorialsList } from "../../../../hooks/useApi"

//Redux
import { useDispatch } from "react-redux"
import { set_user_memorials_list } from "../../../../redux/Profile/MemorialsList/memorialslist.actions"

export const useProfileApi = () => {

    const dispatch = useDispatch()
    const { getItem } = useLocalStorages()
    
    const setMemorialsList = () => {
        return getMemorialsList(getItem("propKey"))
                .then( res => dispatch( set_user_memorials_list(res.data) ) )
    }

    return {
        setMemorialsList,
    }

}