import { SHOW_SPINNER } from "./spinner.actions"

const initialState = {
    isSpinner: false,
}

export const spinnerReducer = ( state = initialState, action ) => {
    switch(action.type) {
        case SHOW_SPINNER:
            return {
                ...state,
                isSpinner: action.payload.prop,
            }
        default: return state
    }
}