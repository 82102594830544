let apiBaseUrl = window.location.hostname.includes("localhost")
  ? "https://dev.beforever.online"
  : `https://${window.location.hostname}`;

export default {
  baseApiUrl: apiBaseUrl,
  facebookAppId: "6899338773427301",
  googleClientId:
    "602446079177-d6k4n0lc3berntd6g1qb9o9ni7r2j418.apps.googleusercontent.com",
  googleMapsApi:
    "https://maps.google.com/maps/api/js?sensor=false&key=AIzaSyDmAtQDtPS1OBt8btdcbtQBm4u8myHkhKY",
};
