//redux
import { createStore, combineReducers, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk'

//Reducers
import { appNavigationReducer } from "./AppNavigation/appNavigation.reducer"
import { settingsReducer } from "../redux/Profile/Settings/settings.reducer"
import { homePageReducer } from "./HomePage/homePage.reducer"
import { profilePagesReducer } from "../redux/ProfilePages/profilePages.reducer"
import { authReducer } from './Auth/auth.reducer'
import { spinnerReducer } from './Spinner/spinner.reducer'
import { modalReducer } from './Modals/modals.reducer'
import { messageToModalReducer } from './MessageToModal/messageToModal.reducer'
import { changePasswordReducer } from './Profile/Settings/ChangePassword/changePassword.reducer'
import { onScrollReducer } from './OnPagesNavigation/onscroll.reducer'
import { memorialSearchFormReducer } from './MemorialsPage/MemorialSearchForm/memorialsearchform.reducer'
import { memorialPageNavigationReducer } from "../redux/MemorialsPage/MemorialPageNavigation/memorialpagenavigation.reducer"
import { newMemorialReducer } from "../redux/NewMemorial/newmemorial.reducer"
import { currentLayoutReducer } from './NewMemorial/CurrentLayout/currentlayout.reducer'
import { userMemorialsListReducer } from './Profile/MemorialsList/memorialslist.reducer'
import { userDataReducer } from './Profile/UserData/userdata.reducer'
import { mirrorDataReducer } from './Mirror/MirrorData/mirrordata.reducer'
import { editMemorialReducer } from "./NewMemorial/EditMemorial/editmemorial.reducer"
import { memorialTributesReducer } from './MemorialsPage/MemorialTributes/memorialTributes.reducer'
import { memorialFriendsReducer } from './MemorialsPage/MemorialFriedndsList/memorialfriends.reducer'
import { memorialImagesReducer } from "./MemorialsPage/MemorialImages/memorialimages.reducer"
import { personalAreaReducer } from "./PersonalArea/personalarea.reducer"
import { isRenderedReducer } from './MemorialsPage/IsRendered/isrendered.reducer'
import { layoutCardsReducer } from './Profile/LayoutCards/layoutcards.reducer'
import { notificationsReducer } from './Profile/Notifications/notifications.reducer'
import { notifysReducer } from "./Notifications/notifications.reducer"
import { invitesReducer } from './invites/invites.reducer'
import { signUpReducer } from './sign_up/sign_up.reducer'
import { headerReducer } from "./Header/header.reducer"
import { memorialPageReducer } from "./MemorialsPage/MemorialPage/memorialpage.reducer"
import { memomovieReducer } from "../MemoMovie/redux/memomovie.reducer"

const appReducer = combineReducers({
    appNavigation: appNavigationReducer,
    homePage: homePageReducer,
    profilePage: profilePagesReducer,
    auth: authReducer,
    spinner: spinnerReducer,
    modal: modalReducer,
    messageToModal: messageToModalReducer,
    newMemorial: newMemorialReducer,
    settings: settingsReducer,
    changePassword: changePasswordReducer,
    onScroll: onScrollReducer,
    memorialSearchForm: memorialSearchFormReducer,
    memorialPageNavigation: memorialPageNavigationReducer,
    currentLayout: currentLayoutReducer,
    userMemorialsList: userMemorialsListReducer,
    userData: userDataReducer,
    mirrorData: mirrorDataReducer,
    editMemorial: editMemorialReducer,
    memorialTributes: memorialTributesReducer,
    memorialFriendsList: memorialFriendsReducer,
    memorialImages: memorialImagesReducer,
    personalArea: personalAreaReducer,
    isRendered: isRenderedReducer,
    layoutCards: layoutCardsReducer,
    notifications: notificationsReducer,
    notifys: notifysReducer,
    invites: invitesReducer,
    signUp: signUpReducer,
    header: headerReducer,
    memorialPage: memorialPageReducer,
    memomovie: memomovieReducer,
})

export const store = createStore(
    appReducer,
    compose(applyMiddleware(thunk))
)