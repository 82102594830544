import {
  SET_PAYMENT_MEMORIAL_DATA,
  SET_USER_DATA,
  SET_USER_PROFILE,
} from "./userdata.actions";

const initialState = {
  userData: null,
  paymentMemorialData: null,
  userProfile: null,
};

export const userDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_USER_DATA:
      return {
        ...state,
        userData: action.payload.userData,
      };
    case SET_PAYMENT_MEMORIAL_DATA:
      return {
        ...state,
        paymentMemorialData: action.payload.data,
      };
    case SET_USER_PROFILE:
      return {
        ...state,
        userProfile: action.payload.userProfile,
      };
    default:
      return state;
  }
};
