import React from 'react'

import logo from '../../../../../assets/images/newLogo.svg'

import styles from './FooterLogo.module.scss'

function FooterLogo() {
  return (
    <div className={styles.footerLogoContainer}>
      <img src={logo} className={styles.image} />
    </div>
  );
}

export default FooterLogo