import classNames from "classnames";
import React from "react";
import { Link } from "react-router-dom";

import styles from "./Button.module.scss";

function Button({ text, onClick, type, href, color, isRound, className }) {
  return (
    <>
      {type === "button" ? (
        <button
          className={classNames(styles.button, className, {
            [styles.deepBlue]: color === "deep",
            [styles.lightBlue]: color === "light",
            [styles.white]: color === "white",
            [styles.round] : isRound
          })}
          onClick={onClick}
        >
          {text}
        </button>
      ) : (
        <Link
          className={classNames(styles.button, {
            [styles.deepBlue]: color === "deep",
            [styles.lightBlue]: color === "light",
            [styles.white]: color === "white",
            [styles.round] : isRound
          })}
          to={href}
        >
          {text}
        </Link>
      )}
    </>
  );
}

export default Button;
