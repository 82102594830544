export const SET_MEMORIAL_MEMORIAL_COORDS = "MEMORIALCOORDS/SET_MEMORIAL_MEMORIAL_COORDS"
export const SET_MEMORIAL_PHOTOS_COORDS = "MEMORIALSCOORDS/SET_MEMORIAL_PHOTOS_COORDS"
export const SET_MEMORIAL_TRIBUTES_COORDS = "MEMORIALSCOORDS/SET_MEMORIAL_TRIBUTES_COORDS"
export const SET_MEMORIAL_EVENTS_COORDS = "MEMORIALSCOORDS/SET_MEMORIAL_EVENTS_COORDS"

export const set_memorial_memorial_coords = coords => {
    return {
        type: SET_MEMORIAL_MEMORIAL_COORDS,
        payload: {
            coords,
        }
    }
}

export const set_memorial_photos_coords = coords => {
    return {
        type: SET_MEMORIAL_PHOTOS_COORDS,
        payload: {
            coords,
        }
    }
}

export const set_memorial_tributes_coords = coords => {
    return {
        type: SET_MEMORIAL_TRIBUTES_COORDS,
        payload: {
            coords,
        }
    }
}

export const set_memorial_events_coords = coords => {
    return {
        type: SET_MEMORIAL_EVENTS_COORDS,
        payload: {
            coords
        }
    }
}