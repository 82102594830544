import React, { useEffect, useState } from "react";
import {Link, NavLink, useLocation} from 'react-router-dom'
import ScrollIntoView from 'react-scroll-into-view'
import {
  FOOTER_LINKS_LEFT,
  FOOTER_LINKS_RIGHT,
} from "../../../../../constants";

import styles from './FooterLinks.module.scss'

function FooterLinks() {

  let location = useLocation();

  const [onScroll, setOnScroll] = useState(false);

  location = {
    pathname:"/",
    hash: "#tarif"
  }

  useEffect(()=> {
    if (location.hash && onScroll) {
        let elem = document.getElementById(location.hash.slice(1))
        if (elem) {
            elem.scrollIntoView({behavior: "smooth", block: "start", inline: "start"})
        }
        setOnScroll(true)
    } else {
    window.scrollTo({top:0,left:0, behavior: "smooth"})
    }
  }, [location,])

      
  return (
    <div className={styles.footerLinksMainContainer}>
      <div className={styles.linksContainer}>
        {FOOTER_LINKS_LEFT.map((link,index) => {
          if(link.href == '/pricing') {
            return <ScrollIntoView key={index} selector="#tarif"><Link onClick={() => setOnScroll(true)} key={index} to={"/"}>{link.text}</Link></ScrollIntoView>;
          } else {
            return <Link key={index} to={link.href}>{link.text}</Link>;
          } 
        })}
      </div>
      <div className={styles.linksContainer}>
        {FOOTER_LINKS_RIGHT.map((link,index) => {
          return <Link key={index} to={link.href}>{link.text}</Link>;
        })}
      </div>
    </div>
  );
}

export default FooterLinks;
