export const SET_SETTINGS_EMAIL = "SETTINGS/SET_SETTINGS_EMAIL"
export const SET_SETTINGS_NAME = "SETTINGS/SET_SETTINGS_NAME"
export const SET_SETTINGS_SURNAME = "SETTINGS/SET_SETTINGS_SURNAME"
export const SET_SETTINGS_COUNTRY = "SETTINGS/SET_SETTINGS_COUNTRY"
export const SET_SETTINGS_SERVER_DATA = "SETTINGS/SET_SETTINGS_SERVER_DATA"
export const CHANGE_USER_AVATAR = "SETTINGS/CHANGE_USER_AVATAR"
export const LOAD_IMAGE = "SETTINGS/LOAD_IMAGE"
export const CHANGE_CROPPER_IMG = "SETTINGS/CAHNGE_CROPPER_IMG"
export const SET_IS_AVATAR_SAVED = "SETTINGS/SET_IS_AVATAR_SAVED"

export const set_settings_email = email => {
    return {
        type: SET_SETTINGS_EMAIL,
        payload: {
            email,
        }
    }
}

export const set_settings_name = name => {
    return {
        type: SET_SETTINGS_NAME,
        payload: {
            name,
        }
    }
}

export const set_settings_surname = surname => {
    return {
        type: SET_SETTINGS_SURNAME,
        payload: {
            surname,
        }
    }
}

export const set_settings_country = country => {
    return {
        type: SET_SETTINGS_COUNTRY,
        payload: {
            country
        }
    }
}

export const  load_image = img => {
    return {
        type: LOAD_IMAGE,
        payload: {
            img,
        }
    }
}

export const change_user_avatar = img => {
    return {
        type: CHANGE_USER_AVATAR,
        payload: {
            img,
        }
    }
}

export const change_cropper_img = img => {
    return {
        type: CHANGE_CROPPER_IMG,
        payload: {
            img,
        }
    }
}

export const set_is_avatar_saved = prop => {
    return {
        type: SET_IS_AVATAR_SAVED,
        payload: {
            prop,
        }
    }
}