import { SET_LAYOUT_CLASSES } from "./layoutcards.actions"

const initialState = {
    classes: "",
}

export const layoutCardsReducer = ( state = initialState, action ) => {
    switch( action.type ) {
        case SET_LAYOUT_CLASSES:
            return {
                ...state,
                classes: action.payload.classes,
            }
        default: return state
    }
}