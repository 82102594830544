import { useEffect } from "react"
import { useSelector } from "react-redux"

export const useLogout = () => {
    let logoutTimeout
    const logoutTime = 30 * 60 * 1000

    const userData = useSelector(state => state.userData.userData)

    const reloadPage = () => {
        localStorage.clear()
        window.location.reload()
    }

    const clearLogoutTimer = async () => {
        await document.removeEventListener("mousedown", resetLogoutTimer)
        await document.removeEventListener("keydown", resetLogoutTimer)
        await clearTimeout(logoutTimeout)
    }

    async function resetLogoutTimer() {
        await clearLogoutTimer()

        return await setLogoutTimer(logoutTime)
    }

    const setLogoutTimer = async time => {
        await document.addEventListener("mousedown", resetLogoutTimer)
        await document.addEventListener("keydown", resetLogoutTimer)

        logoutTimeout = await setTimeout(() => reloadPage(), time)
    }

    useEffect(() => {
        if ( !userData ) {
            clearLogoutTimer()
            return
        }

        if ( userData ) {
            setLogoutTimer(logoutTime)
        }

        return () => clearLogoutTimer()
    }, [userData])

    return logoutTimeout
}