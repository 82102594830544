export const SET_POSTER_IMG = "NEWMEMORIAL/SET_POSTER_IMG";
export const SET_NEW_MEMORIAL_GIVEN_NAME =
  "NEWMEMORIAL/SET_NEW_MEMORIAL_GIVEN_NAME";
export const SET_NEW_MEMORIAL_SURNAME = "NEWMEMORIAL/SET_NEW_MEMORIAL_SURNAME";
export const SET_NEW_MEMORIAL_SECOND_NAME =
  "NEWMEMORIAL/SET_NEW_MEMORIAL_SECOND_NAME";
export const SET_NEW_MEMORIAL_BORN_DATE =
  "NEWMEMORIAL/SET_NEW_MEMORIAL_BORN_DATE";
export const SET_NEW_MEMORIAL_PASSED_DATE =
  "NEWMEMORIAL/SET_NEW_MEMORIAL_PASSED_DATE";

export const SET_NEW_MEMORIAL_BORN_DAY =
  "NEWMEMORIAL/SET_NEW_MEMORIAL_BORN_DAY";
export const SET_NEW_MEMORIAL_BORN_MONTH =
  "NEWMEMORIAL/SET_NEW_MEMORIAL_BORN_MONTH";
export const SET_NEW_MEMORIAL_BORN_YEAR =
  "NEWMORIAL/SET_NEW_MEMORIAL_BORN_YEAR";

export const SET_NEW_MEMORIAL_PASSED_AWAY_DAY =
  "NEWMEMORIAL/SET_NEW_MEMORIAL_PASSED_AWAY_DAY";
export const SET_NEW_MEMORIAL_PASSED_AWAY_MONTH =
  "NEWMEMORIAL/SET_NEW_MEMORIAL_PASSED_AWAY_MONTH";
export const SET_NEW_MEMORIAL_PASSED_AWAY_YEAR =
  "NEWMEMORIAL/SET_NEW_MEMORIAL_PASSED_AWAY_YEAR";

export const SET_NEW_MEMORIAL_OCUPATION =
  "NEWMEMORIAL/SET_NEW_MEMORIAL_OCUATION";

export const SET_EDITOR = "EDITOR/SET_EDITOR";
export const SET_NEW_MEMO_ID = "EDITOR/SET_NEW_MEMO_ID";

export const SET_IS_CREATED = "NEWMEMORIAL/SET_IS_CREATED";

export const SET_NEW_MEMORIAL_EVENT_NAME =
  "NEWMEMORIAL/SET_NEW_MEMORIAL_EVENT_NAME";
export const SET_NEW_MEMORIAL_EVENT_DATE =
  "NEWMEMORIAL/SET_NEW_MEMORIAL_EVENT_DATE";

export const set_new_memorial_event_name = (eventName) => {
  return {
    type: SET_NEW_MEMORIAL_EVENT_NAME,
    payload: {
      eventName,
    },
  };
};

export const set_new_memorial_event_date = (eventDate) => {
  return {
    type: SET_NEW_MEMORIAL_EVENT_DATE,
    payload: {
      eventDate,
    },
  };
};

export const set_new_memorial_given_name = (givenName) => {
  return {
    type: SET_NEW_MEMORIAL_GIVEN_NAME,
    payload: {
      givenName,
    },
  };
};

export const set_new_memorial_surname = (surName) => {
  return {
    type: SET_NEW_MEMORIAL_SURNAME,
    payload: {
      surName,
    },
  };
};

export const set_new_memorial_second_name = (secondName) => {
  return {
    type: SET_NEW_MEMORIAL_SECOND_NAME,
    payload: {
      secondName,
    },
  };
};

export const set_new_memorial_born_date = (bornDate) => {
  return {
    type: SET_NEW_MEMORIAL_BORN_DATE,
    payload: {
      bornDate,
    },
  };
};

export const set_new_memorial_passed_date = (passedDate) => {
  return {
    type: SET_NEW_MEMORIAL_PASSED_DATE,
    payload: {
      passedDate,
    },
  };
};

export const set_new_memorial_born_day = (day) => {
  return {
    type: SET_NEW_MEMORIAL_BORN_DAY,
    payload: {
      day,
    },
  };
};

export const set_new_memorial_born_month = (month) => {
  return {
    type: SET_NEW_MEMORIAL_BORN_MONTH,
    payload: {
      month,
    },
  };
};

export const set_new_memorial_born_year = (year) => {
  return {
    type: SET_NEW_MEMORIAL_BORN_YEAR,
    payload: {
      year,
    },
  };
};

export const set_new_memorial_passed_away_day = (day) => {
  return {
    type: SET_NEW_MEMORIAL_PASSED_AWAY_DAY,
    payload: {
      day,
    },
  };
};

export const set_new_memorial_passed_away_month = (month) => {
  return {
    type: SET_NEW_MEMORIAL_PASSED_AWAY_MONTH,
    payload: {
      month,
    },
  };
};

export const set_new_memorial_passed_away_year = (year) => {
  return {
    type: SET_NEW_MEMORIAL_PASSED_AWAY_YEAR,
    payload: {
      year,
    },
  };
};

export const set_new_memorial_ocupation = (ocupation) => {
  return {
    type: SET_NEW_MEMORIAL_OCUPATION,
    payload: {
      ocupation,
    },
  };
};

export const set_editor = (prop) => {
  return {
    type: SET_EDITOR,
    payload: {
      prop,
    },
  };
};

export const set_new_memo_id = (id) => {
  return {
    type: SET_NEW_MEMO_ID,
    payload: {
      id,
    },
  };
};

export const set_is_created = (prop) => {
  return {
    type: SET_IS_CREATED,
    payload: {
      prop,
    },
  };
};
