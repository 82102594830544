//React
import React, { useRef, useState } from "react";
import { Link, useLocation, NavLink } from "react-router-dom";

import styles from "./HeaderProfileMenu.module.scss";

//Material-Ui
import { Avatar } from "@material-ui/core";

//Redux
import { connect } from "react-redux";
import { userDataSelector } from "../../../redux/Profile/UserData/userdata.selectors";
import { currentProfilePageSelector } from "../../../redux/ProfilePages/profilePages.selectors";
import { newNotifysListSelector } from "../../../redux/Notifications/notifications.selectors";
import {
  renderLogoutModal,
  renderProfileMenu,
} from "../../../redux/Modals/renderModals";
import { personalAreaIdSelector } from "../../../redux/PersonalArea/resonalarea.selectors";
import { getPersonalInfo } from "../../../hooks/useApi";
import { set_user_personal_data } from "./../../../redux/PersonalArea/personalarea.actions";
import { personalDataSelector } from "./../../../redux/PersonalArea/resonalarea.selectors";
import { useEffect } from "react";
import classNames from "classnames";

const HeaderProfileMenu = (props) => {
  const ref = useRef(null);
  const [mobMenu, setMobMenu] = useState(false);

  const onLogout = () => {
    localStorage.clear();
    window.location.reload();
  };

  const isUserId = localStorage.getItem("userId");
  const [userInfo, setUserInfo] = useState();

  useEffect(() => {
    getPersonalInfo(isUserId).then((res) => setUserInfo(res.data));
  }, []);

  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        setMobMenu(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);

  return (
    <li className={styles.menu} ref={ref}>
      <Avatar
        onClick={() => setMobMenu((prev) => !prev)}
        src={userInfo?.avatar}
        style={{ margin: "auto" }}
        className="profilemenu-headerAvatar"
      />
      {mobMenu && (
        <ul className={classNames(styles.menuList)}>
          <li className={styles.menuLink} onClick={() => setMobMenu(false)}>
            <NavLink to={`/${isUserId}/personal_area`}>
              <b>My Memo Pages</b>
            </NavLink>
          </li>
          <li className={styles.menuLink} onClick={() => setMobMenu(false)}>
            <Link to="/profile/new">
              <b>New Memo Page</b>
            </Link>
          </li>
          <li className={styles.menuLink} onClick={() => setMobMenu(false)}>
            <Link to="/profile/new">
              <b>New Memo Movie</b>
            </Link>
          </li>
          <li className={styles.menuLink} onClick={() => setMobMenu(false)}>
            <Link to="/profile/personal_settings" style={{ padding: "5px 0" }}>
              <b>Personal Settings</b>
            </Link>
          </li>
          <li
            className={styles.menuLink}
            onClick={() => onLogout()}
            style={{ cursor: "pointer" }}
          >
            <p>
              <b>Log Out</b>
            </p>
          </li>
        </ul>
      )}
    </li>
  );
};

const mapState = (state) => {
  return {
    userData: userDataSelector(state),
    notifysList: newNotifysListSelector(state),
    currentProfilePage: currentProfilePageSelector(state),
    personalAreaId: personalAreaIdSelector(state),
    personalData: personalDataSelector(state),
  };
};

const mapDispatch = {
  renderLogoutModal: (prop) => renderLogoutModal(prop),
  renderProfileMenu: (prop) => renderProfileMenu(prop),
};

export default connect(mapState, mapDispatch)(HeaderProfileMenu);
