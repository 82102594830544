import React from "react"
import { Link, useLocation } from "react-router-dom"
import LoginLogo from "../../../assets/images/login.png"

const HeaderAccountNavigation = () => {
    const location = useLocation()
    const page = location.pathname
    
    return(
        <div className="header_navig d_flex">
            <Link 
                className="login"
                to={{
                    pathname: "/log_in",
                    state: { prevPage: page }
                }}
            >
                <img src={LoginLogo} alt="sign_in" />
                <span>Log in</span>
            </Link>
        </div>
    )
}

export default HeaderAccountNavigation