import { SHOW_SUCCESS_MODAL, SHOW_TO_TRIBUTES_MODAL, SHOW_ERROR_MODAL, SHOW_WARRNNING_MODAL, SHOW_QR_MODAL, TOGGLE_INVITE_MODAL, SET_IS_GALERY, SET_IS_FRIEND_MODAL, SET_IS_COOKIE, SET_IS_FILM_MODAL, SHOW_LOGOUT_MODAL, SHOW_FOR_CONFIRM_MODAL, SHOW_TO_PAYMENTS_MODAL, SET_IS_FRIEND_POPUP_MODAL, SHOW_TO_CREATE_MEMOPAGE_MODAL, SHOW_ADD_PHOTO_TO_GALERY, SET_IS_PROFILE_MENU, SHOW_REINVITE_MODAL, SET_IS_FILES_LOADING_POPUP, SET_IS_MODAL_WITH_CHECKBOX } from "./modals.actions"

const initialState = {
    isSuccessModal: false,
    isErrorModal: false,
    isWarrningModal: false,
    isLogoutModal: false,
    isForConfirmModal: false,
    isQRModal: false,
    isInviteModal: false,
    isGalery: false,
    isFriendModal: false,
    isFriendPopUpModal: false,
    isCookie: false,
    isFilmModal: false,
    isToPaymentModal: false,
    isToCreateMemoPageModal: false,
    isAddPhotoToGaleryModal: false,
    isToTributesModal: false,
    isProfileMenu: false,
    isReinviteModalShown: false,
    isFilesLoadingPopup: false,
    isModalWithCheckbox: false,
    isTributesModal: false
}

export const modalReducer = ( state = initialState, action ) => {
    switch(action.type) {
        case SHOW_SUCCESS_MODAL:
            return {
                ...state,
                isSuccessModal: action.payload.prop,
            }
        case SHOW_ERROR_MODAL:
            return {
                ...state,
                isErrorModal: action.payload.prop,
            }
        case SHOW_WARRNNING_MODAL:
            return {
                ...state,
                isWarrningModal: action.payload.prop
            }
        case SHOW_LOGOUT_MODAL:
            return {
                ...state,
                isLogoutModal: action.payload.prop
            }
        case SHOW_FOR_CONFIRM_MODAL:
            
            return {
                ...state,
                isForConfirmModal: action.payload.prop
            }
            
        case SHOW_QR_MODAL:
            return {
                ...state,
                isQRModal: action.payload.prop,
            }
        case TOGGLE_INVITE_MODAL:
            return {
                ...state,
                isInviteModal: action.payload.prop,
            }
        case SET_IS_GALERY:
            return {
                ...state,
                isGalery: action.payload.prop,
            }
        case SET_IS_FRIEND_MODAL:
            return {
                ...state,
                isFriendModal: action.payload.prop,
            }
        case SET_IS_FRIEND_POPUP_MODAL:
            return {
                ...state,
                isFriendPopUpModal: action.payload.prop,
            }
        case SET_IS_COOKIE:
            return {
                ...state,
                isCookie: action.payload.prop,
            }
        case SET_IS_FILM_MODAL:
            return {
                ...state,
                isFilmModal: action.payload.prop,
            }
        case SHOW_TO_PAYMENTS_MODAL:
            return {
                ...state,
                isToPaymentModal: action.payload.prop,
            }
        case SHOW_TO_CREATE_MEMOPAGE_MODAL:
            return {
                ...state,
                isToCreateMemoPageModal: action.payload.prop,
            }
        case SHOW_ADD_PHOTO_TO_GALERY:
            return {
                ...state,
                isAddPhotoToGaleryModal: action.payload.prop,
            }
        case SHOW_TO_TRIBUTES_MODAL:
            return {
                ...state,
                isToTributesModal: action.payload.prop,
            }
        case SET_IS_PROFILE_MENU:
            return {
                ...state,
                isProfileMenu: action.payload.prop,
            }
        case SHOW_REINVITE_MODAL:
            return {
                ...state,
                isReinviteModalShown: action.payload.prop,
            }
        case SET_IS_FILES_LOADING_POPUP:
            return {
                ...state,
                isFilesLoadingPopup: action.payload.prop,
            }
        case SET_IS_MODAL_WITH_CHECKBOX:
            return {
                ...state,
                isModalWithCheckbox: action.payload.prop,
            }
        default: return state
    }
}