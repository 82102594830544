import { SET_FILTERED_NOTIFYS_LIST, SET_IS_NOTIFY_ERROR, SET_NEW_NOTIFYS_LIST } from "./notifications.actions"

const initialState = {
    newNotifysList: null,
    filteredNotifysList: null,
    isNotifyError: false,
}

export const notifysReducer = ( state = initialState, action ) => {
    switch(action.type) {
        case SET_NEW_NOTIFYS_LIST:
            return {
                ...state,
                newNotifysList: action.payload.list,
            }
        case SET_FILTERED_NOTIFYS_LIST:
            return {
                ...state,
                filteredNotifysList: action.payload.list,
            }
        case SET_IS_NOTIFY_ERROR:
            return {
                ...state,
                isNotifyError: action.payload.prop,
            }
        default: return state
    }
}