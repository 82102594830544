import { SET_ACTIVE_MEMORIAL_ID, SET_MEMORIALS_NOTIFICATIONS_TABLE } from "./notifications.actions"

const initialState = {
    activeMemorialId: null,
    memorialsNotificationsTable: null,
}

export const notificationsReducer = ( state = initialState, action ) => {
    switch( action.type ) {
        case SET_ACTIVE_MEMORIAL_ID:
            return {
                ...state,
                activeMemorialId: action.payload.id,
            }
        case SET_MEMORIALS_NOTIFICATIONS_TABLE:
            return {
                ...state,
                memorialsNotificationsTable: action.payload.data,
            }
            default: return state
    }
}