//React
import React, { useEffect, lazy } from "react"
import "./Header.scss"
import { Link } from "react-router-dom"

//Images
import HeaderLogo from "../../assets/images/logo.webp"

//Components
import HeaderAccountNavigation from "./HeaderAccountNavigation/HeaderAccountNavigation"
import HeaderNotificationsMenu from "./HeaderNotificationsMenu/HeaderNotificationsMenu"
import HeaderProfileMenu from "./HeaderProfileMenu/HeaderProfileMenu"
import HeaderNavigation from "./HeaderNavigation/HeaderNavigation"

//Modals-components
import SuccessModal from "../ModalsItems/SuccessModal/SuccessModal"
import ErrorModal from "../ModalsItems/ErrorModal/ErrorModal"
import WarrningModal from "../ModalsItems/WarrningModal/WarrningModal"
import LogoutModal  from "../ModalsItems/LogoutModal/LogoutModal"
import HeaderLoadingSpinner from "./HeaderLoadingSpinner/HeaderLoadingSpinner"

//Hooks
import { useHomeHeaderApi } from "./hooks/useHeaderApi"
import { useLogout } from "./hooks/useLogout"
import { useLocalStorages } from "../../hooks/useLocalStorages"

//Redux
import { connect } from "react-redux"
import { authSelector } from "../../redux/Auth/auth.selectors"
import { isErrorModalSelector, isSuccessModalSelector, isWarrningModalSelector, isLogoutModalSelector, isForConfirmModalSelector, isToPaymentModalSelector, isIniviteModalSelector, isToCreateMemoPageModalSelector, isModalWithCheckboxSelector } from "../../redux/Modals/modals.selectors"
import { currentProfilePageSelector } from "../../redux/ProfilePages/profilePages.selectors"
import { set_user_data } from "../../redux/Profile/UserData/userdata.actions"
import { userDataSelector } from "../../redux/Profile/UserData/userdata.selectors"
import { set_new_notifys_list } from "../../redux/Notifications/notifications.actions"
import { isProfileMenuSelector } from "../../redux/Header/header.selectors"
import { set_is_profile_menu } from "../../redux/Header/header.actions"
import { spinnerSelector } from "../../redux/Spinner/spinner.selector"
import Spinner from "../ModalsItems/Spinner/Spinner"

const Header = props => {
    const { 
        userData, isSuccesModal, isErrorModal, 
        Auth, isInviteModal, isToCreateMemorialModal, isWarrningModal,
        isLogoutModal, isForConfirmModal, isToPaymentModal,
        isProfileMenu, setIsProfileMenu, isModalWithCheckbox, isSpinner
    } = props

    const ForConfirmModal = lazy(() => import("../ModalsItems/ForConfirmModal/ForConfirmModal"))
    const ToPaymentModal = lazy(() => import("../ModalsItems/ToPaymentModal/ToPaymentModal"))
    const InviteModal = lazy(() => import("../ModalsItems/InviteModal/InviteModal"))
    const ToCreateMemoPageModal = lazy(() => import("../ModalsItems/ToCreateMemoPageModal/ToCreateMemoPageModal"))
    const ModalWithCheckBox = lazy(() => import("../ModalsItems/ModalWithCheckBox/ModalWithCheckBox"))

    const { setUserData, setNotifysList } = useHomeHeaderApi()
    const { getItem } = useLocalStorages()
    const logoutTimer = useLogout()

    useEffect(() => {
        if (Auth) {
            (async () => {
                await setNotifysList()
                await setUserData(getItem("propKey"))
            })()
        }
    }, [Auth])

    return(
        <header className="header">
            <div className="header_row d_flex">
                <div className="header_right">
                    <div className={isProfileMenu ? "hamburger open" : "hamburger"}  onClick={() => setIsProfileMenu(!isProfileMenu)}>
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                    <div className="header_right_open d_flex" style={isProfileMenu ? { display: "block" } : { flexWrap: "nowrap", justifyContent: "flex-end" }}>
                    <Link to="/" className="header_logo">
                        <amp-img>
                            <img className="beforever.online" src={HeaderLogo} alt="header-logo" />
                        </amp-img>
                    </Link>
                    <div className="header_menu">
                        <ul className="open_menu d_flex headerdflex" style={{ alignItems: "center" }}>
                            <HeaderNavigation />
                            {Auth && !userData && <HeaderLoadingSpinner />}
                            {Auth && userData && <HeaderProfileMenu/>}
                            {Auth && userData && <HeaderNotificationsMenu />}
                        </ul>
                    </div>
                    {!Auth && <HeaderAccountNavigation />}
                    </div>
                </div>
            </div>
            <div className="containerBe">
                {isSuccesModal && <SuccessModal />}
                {isErrorModal && <ErrorModal />}
                {isWarrningModal && <WarrningModal />}
                {isLogoutModal && <LogoutModal />}
                {isForConfirmModal && <ForConfirmModal />}
                {isToPaymentModal   &&  <ToPaymentModal />}
                {isInviteModal && <InviteModal />}
                {isToCreateMemorialModal && <ToCreateMemoPageModal />}
                {isModalWithCheckbox && <ModalWithCheckBox />}
                {isSpinner && <Spinner />}
            </div>
        </header>
    )
}

const mapState = state => {
    return {
        Auth: authSelector(state),
        isSuccesModal: isSuccessModalSelector(state),
        isErrorModal: isErrorModalSelector(state),
        isWarrningModal: isWarrningModalSelector(state),
        isLogoutModal: isLogoutModalSelector(state),
        isForConfirmModal: isForConfirmModalSelector(state),
        isToPaymentModal: isToPaymentModalSelector(state),
        isInviteModal: isIniviteModalSelector(state),
        isModalWithCheckbox: isModalWithCheckboxSelector(state),
        isToCreateMemorialModal: isToCreateMemoPageModalSelector(state),
        isSpinner: spinnerSelector(state),
        isProfileMenu: isProfileMenuSelector(state),
        currentProfilePage: currentProfilePageSelector(state),
        userData: userDataSelector(state),
    }
}

const mapDispatch = {
    reduxSetUserData: data => set_user_data(data),
    reduxSetNotifysList: list => set_new_notifys_list(list),
    setIsProfileMenu: prop => set_is_profile_menu(prop),
}

export default connect(mapState, mapDispatch)(Header)