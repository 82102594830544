export const SHOW_SUCCESS_MODAL = "MODALS/SHOW_SUCCESS_MODAL"
export const SHOW_ERROR_MODAL = "MODALS/SHOW_ERROR_MODAL"
export const SHOW_WARRNNING_MODAL = "MODALS/SHOW_WARRNING_MODAL"
export const SHOW_LOGOUT_MODAL = "MODALS/SHOW_LOGOUT_MODAL"
export const SHOW_FOR_CONFIRM_MODAL = "MODALS/SHOW_FOR_CONFIRM_MODAL"
export const SHOW_QR_MODAL = "MODALS/SHOW_QR_MODAL"
export const TOGGLE_INVITE_MODAL = "MODALS/TOGGLE_INVITE_MODAL"
export const SET_IS_GALERY = "MODALS/SET_IS_GALERY"
export const SET_IS_FRIEND_MODAL = "MODALS/SET_IS_FRIEND_MODAL"
export const SET_IS_COOKIE = "MODALS/SET_IS_COOKIE"
export const SET_IS_FILM_MODAL = "MODALS/SET_IS_FILM_MODAL"
export const SHOW_TO_PAYMENTS_MODAL = "MODALS/SHOW_TO_PAYMENTS_MODAL"
export const SET_IS_FRIEND_POPUP_MODAL = "MODALS/SET_IS_FRIEND_POPUP_MODAL"
export const SHOW_TO_CREATE_MEMOPAGE_MODAL = "MODALS/SHOW_TO_CREATE_MEMOPAGE_MODAL"
export const SHOW_ADD_PHOTO_TO_GALERY = "MODALS/SHOW_ADD_PHOTO_TO_GALERY"
export const SHOW_TO_TRIBUTES_MODAL = "MODALS/SHOW_TO_TRIBUTES_MODAL"
export const SET_IS_PROFILE_MENU = "MODALS/SET_IS_PROFILE_MENU"
export const SHOW_REINVITE_MODAL = "MODALS/SHOW_REINVITE_MODAL"
export const SET_IS_FILES_LOADING_POPUP = "MODALS/SET_IS_FILES_LOADING_POPUP"
export const SET_IS_MODAL_WITH_CHECKBOX = "MODALS/SET_IS_MODAL_WITH_CHECKBOX"

export const show_success_modal = prop => {
    return {
        type: SHOW_SUCCESS_MODAL,
        payload: {
            prop,
        }
    }
}

export const show_error_modal = prop => {
    return {
        type: SHOW_ERROR_MODAL,
        payload: {
            prop,
        }
    }
}

export const show_warrning_modal = prop => {
    return {
        type: SHOW_WARRNNING_MODAL,
        payload: {
            prop,
        }
    }
}

export const show_logout_modal = prop => {
    return {
        type: SHOW_LOGOUT_MODAL,
        payload: {
            prop,
        }
    }
}

export const show_for_confirm_modal = prop => {
    
    return {
        type: SHOW_FOR_CONFIRM_MODAL,
        payload: {
            prop,
        }
    }
}

export const show_qr_modal = prop => {
    return {
        type: SHOW_QR_MODAL,
        payload: {
            prop
        }
    }
}

export const toggleInviteModal = prop => {
    return {
        type: TOGGLE_INVITE_MODAL,
        payload: {
            prop,
        }
    }
}

export const set_is_galery = prop => {
    return {
        type: SET_IS_GALERY,
        payload: {
            prop,
        }
    }
}

export const set_is_friend_modal = prop => {
    return {
        type: SET_IS_FRIEND_MODAL,
        payload: {
            prop,
        }
    }
}

export const set_is_friend_popup_modal= prop => {
    return {
        type: SET_IS_FRIEND_POPUP_MODAL,
        payload: {
            prop,
        }
    }
}

export const set_is_cookie = prop => {
    return {
        type: SET_IS_COOKIE,
        payload: {
            prop,
        }
    }
}

export const set_is_film_modal = prop => {
    return {
        type: SET_IS_FILM_MODAL,
        payload: {
            prop,
        }
    }
}

export const show_to_payments_modal = prop => {
    return {
        type: SHOW_TO_PAYMENTS_MODAL,
        payload: {
            prop,
        }
    }
}

export const show_to_create_memopage_modal = prop => {
    return {
        type: SHOW_TO_CREATE_MEMOPAGE_MODAL,
        payload: {
            prop,
        }
    }
}

export const show_add_photo_to_galery_modal = prop => {
    return {
        type: SHOW_ADD_PHOTO_TO_GALERY,
        payload: {
            prop,
        }
    }
}

export const show_to_atribute_modal = prop => {
    return {
        type: SHOW_TO_TRIBUTES_MODAL,
        payload: {
            prop,
        }
    }
}

export const set_is_profile_menu = prop => {
    return {
        type: SET_IS_PROFILE_MENU,
        payload: {
            prop,
        }
    }
}

export const show_reinvite_modal = prop => {
    return {
        type: SHOW_REINVITE_MODAL,
        payload: {
            prop,
        }
    }
}

export const set_is_files_loading_popup = prop => {
    return {
        type: SET_IS_FILES_LOADING_POPUP,
        payload: {
            prop,
        }
    }
}

export const set_is_modal_with_checkbox = prop => {
    return {
        type: SET_IS_MODAL_WITH_CHECKBOX,
        payload: {
            prop,
        }
    }
}