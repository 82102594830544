import { SET_NEW_PASSWORD, CONFIRM_NEW_PASSWORD } from "./changePassword.actions";

const initialState = {
    newPassword: "",
    confirmNewPassword: "",
}

export const changePasswordReducer = ( state = initialState, action ) => {
    switch( action.type ) {
        case SET_NEW_PASSWORD:
            return {
                ...state,
                newPassword: action.payload.password
            }
        case CONFIRM_NEW_PASSWORD:
            return {
                ...state,
                confirmNewPassword: action.payload.password
            }
        default: return state
    }
}