import React, { useEffect } from "react"
import "./ErrorModal.scss"

//img
import WhiteCloseImg from "../../../assets/images/white_close.png"
import ErrorImg from "../../../assets/images/error_img.png"

//Redux
import { connect } from "react-redux"
import { errorTextSelector } from "../../../redux/MessageToModal/messageToModal.selectors"
import { show_error_modal } from "../../../redux/Modals/modals.actions"
import { set_error_text } from "../../../redux/MessageToModal/messageToModal.actions"

const ErrorModal = ({ errorText, setErrorText, showErrorModal }) => {

    useEffect(() => {
        return function cleanup() {
            setTimeout(() => {
                setErrorText("")
                showErrorModal(false)
            }, 3000)
        }
    })

    useEffect(() => {
        const onClick = e => {
            if ( e.keyCode === 27 ) {
                showErrorModal(false)
            }
        }
        document.addEventListener("keydown", onClick)

        return () => document.removeEventListener("keydown", onClick)
    }, [])

    return(
        <div className="errorModal">
            <div className="errorModal-close" onClick={ () => showErrorModal(false) }>
                <img style={{ height: "100%", width: "100%" }} src={WhiteCloseImg} alt="Close" />
            </div>
            <div className="errorModal-container">
                <div className="errorModal-img">
                    <img src={ErrorImg} alt="Error Img" />
                </div>
                <div className="errorModal-text" style={{ wordBreak: "break-word" }}>{errorText}</div>
            </div>
        </div>
    )
}

const mapState = state => {
    return {
        errorText: errorTextSelector(state),
    }
}

const mapDispatch = {
    showErrorModal: prop => show_error_modal(prop),
    setErrorText: text => set_error_text(text),
}

export default connect(mapState, mapDispatch)(ErrorModal)