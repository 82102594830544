import React from "react";

import styles from "./Address.module.scss";

function Address() {
  return (
    <div className={styles.addressContainer}>
      <p className={styles.subtitle}>Registered Office Address:</p>
      <p className={styles.text}>
        Rua 3 da Matinha, Edifício Altejo,Armazém 101, Marvilla, Lisboa 1950-326
        Lisboa
      </p>
    </div>
  );
}

export default Address;
