//react
import React, { useState, useEffect } from "react";
import "../Pages/HomePage/Home.scss";
import "../Pages/ProfilePage/Profile.scss";

//Components
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import FirstScreenContainer from "../Pages/HomePage/firstScreen/FirstScreenContainer";
import NoneHomeFirstScreen from "../Pages/HomePage/firstScreen/NoneHomeFirstScreen";

//Hooks
import { usePageGenerator } from "./hooks/usePageGenerator";

//Redux
import { connect } from "react-redux";
import { pageTitleSelector } from "../../redux/AppNavigation/appNavigation.selectors";
import Spinner from "../ModalsItems/Spinner/Spinner";
import { isNoneContainerPageSelector } from "../../redux/MemorialsPage/MemorialPage/memorialpage.selectors";
import NewHeader from "../Layout/Header/NewHeader";
import NewFooter from "../Layout/Footer/NewFooter";

const HighOrderPageGenerator = (props) => {
  const { children, pageTitle, isNoneContainerPage } = props;

  const [pageConfig, setPageConfig] = useState({
    pageTitle: "",
    isHomePage: null,
    mainContainerClasses: "first_screen",
    prevClasses: "first_screen",
  });
  const [isMemoMovie, setIsMemoMovie] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const { definePageConfig } = usePageGenerator(setIsLoading);

  useEffect(() => {
    if (pageTitle?.includes("MemoMovie")) {
      setIsMemoMovie(true);
      return;
    }

    if (!pageTitle?.includes("MemoMovie")) {
      definePageConfig(pageTitle, setPageConfig);
      setIsMemoMovie(false);
      return;
    }
  }, [pageTitle]);

  if (isMemoMovie === null || isLoading) return <Spinner isBackground />;

  return (
    <>
      <div>
        <NewHeader />
      </div>
      {isNoneContainerPage ? (
        <>{children}</>
      ) : (
        <div style={{ marginBottom: "auto" }}>{children}</div>
      )}
      <div>
        <NewFooter />
      </div>
    </>
  );
};

const mapState = (state) => {
  return {
    pageTitle: pageTitleSelector(state),
    isNoneContainerPage: isNoneContainerPageSelector(state),
  };
};

export default connect(mapState)(HighOrderPageGenerator);
