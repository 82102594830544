import React from "react"
import { connect } from "react-redux"
import { Link } from "react-router-dom"
import { set_is_cookie } from "../../../redux/Modals/modals.actions"
import "./CoockieModal.scss"

const CoockieModal = ({ setIsCookie }) => {
    return(
        <div className="cookieModal">
            <p className="cookieModal-text">
                We use cookie to provide you with a better expirience on the Life Trace website. You can read more about our use of cookies in our <Link className="cookieModal-text-link" to="/privacy-policy">Privacy Policy</Link> and <Link className="cookieModal-text-link" to="/terms_of_use">Terms Of Use</Link>
            </p>
            <button className="cookieModal-btn" onClick={ () => { setIsCookie(true); document.cookie="isCookie=true" }}>Accept</button>
        </div>
    )
}

const mapDispatch = {
    setIsCookie: prop => set_is_cookie(prop),
}

export default connect(null, mapDispatch)(CoockieModal)