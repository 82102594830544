import { SET_USER_PERSONAL_DATA, SET_PERSONAL_AREA_ID } from "./personalarea.actions"

const initialState = {
    personalAreaId: 0,
    personalData: null,
}

export const personalAreaReducer = ( state = initialState, action ) => {
    switch( action.type ) {
        case SET_USER_PERSONAL_DATA:
            return {
                ...state,
                personalData: action.payload.data
            }
        case SET_PERSONAL_AREA_ID:
            return {
                ...state,
                personalAreaId: action.payload.id
            }
        default: return state
    }
}