//React
import React from "react"
import { Link, useLocation } from "react-router-dom"
import classNames from "classnames"

//Redux
import { connect } from "react-redux"
import { authSelector } from "../../../redux/Auth/auth.selectors"

const HeaderNavigation = props => {
    const { Auth } = props

    const location = useLocation()
    const currentPage = location.pathname

    return(
        <>
            <li className={currentPage === "/" ? classNames("active", "headermenutext") : "headermenutext"}>
                <Link className="headerMenu-link" to="/">Home</Link>
            </li> 
            <li className={currentPage === "/memopages/list" ? classNames("active", "headermenutext") : "headermenutext"}>
                <Link className="headerMenu-link" to="/memopages/list">MemoPages</Link>
            </li>
            <li className={currentPage === "/pricing" ? classNames("active", "headermenutext") : "headermenutext"}>
                <Link className="headerMenu-link" to="/pricing">pricing</Link>
            </li>
            <li className={(Auth && currentPage === "/contact_us") || (!Auth && currentPage === "/about_us") ? classNames("active", "headermenutext") : "headermenutext"}>
                {Auth 
                    ? <Link className="headerMenu-link" to="/contact_us">contact us</Link> 
                    : <Link className="headerMenu-link" to="/about_us">about us</Link>
                }
            </li>
            {!Auth  &&  
                <li className={currentPage === "/faq" ? classNames("active", "headermenutext") : "headermenutext"}>
                    <Link className="headerMenu-link" to="/faq">FAQ</Link>
                </li>
            }
        </>
    )
}

const mapState = state => {
    return {
        Auth: authSelector(state),
    }
}

export default connect(mapState)(HeaderNavigation)