import { SET_USER_MEMORIALS_LIST } from "./memorialslist.actions";

const initialState = {
    memorialsList: null,
}

export const userMemorialsListReducer = ( state = initialState, action ) => {
    switch( action.type ) {
        case SET_USER_MEMORIALS_LIST:
            return {
                ...state,
                memorialsList: action.payload.list,
            }
        default: return state
    }
}