export const SET_IS_UNPAYED_PAGE = "MEMORIAL_PAGE/SET_IS_UNPAYED_PAGE"
export const SET_IS_PRIVAT_PAGE = "MEMORIAL_PAGE/SET_IS_PRIVAT_PAGE"
export const SET_MEMORIAL_CONFIG_DATA = "MEMORIAL_PAGE/SET_MEMORIAL_CONFIG_DATA"
export const SET_MEMORIAL_CONFIG_IMAGES = "MEMORIAL_PAGE/SET_MEMORIAL_CONFIG_IMAGES"
export const SET_MEMORIAL_CONFIG_FRIENDS = "MEMORIAL_PAGE/SET_MEMORIAL_CONFIG_FRIENDS"
export const SET_MEMORIAL_CONFIG_TRIBUTES = "MEMORIAL_PAGE/SET_MEMORIAL_CONFIG_TRIBUTES"
export const SET_FIRST_STRING_IMAGES_LIST = "MEMORIAL_PAGE/SET_FIRST_STRING_IMAGES_LIST"
export const SET_SECOND_STRING_IMAGES_LIST = "MEMORIAL_PAGE/SET_SECOND_STRING_IMAGES_LIST"

export const SET_IS_NONECONTAINER_PAGE = "MEMORIAL_PAGE/SET_IS_NONECONTAINER_PAGE"

export const set_is_privat_page = prop => {
    return {
        type: SET_IS_PRIVAT_PAGE,
        payload: {
            prop
        }
    }
}

export const set_is_unpayed_page = prop => {
    return {
        type: SET_IS_UNPAYED_PAGE,
        payload: {
            prop
        }
    }
}

export const set_is_nonecontainer_page = prop => {
    return {
        type: SET_IS_NONECONTAINER_PAGE,
        payload: {
            prop,
        }
    }
}

export const set_memorial_config_data = data => {
    return {
        type: SET_MEMORIAL_CONFIG_DATA,
        payload: {
            data,
        }
    }
}

export const set_memorial_config_images = images => {
    return {
        type: SET_MEMORIAL_CONFIG_IMAGES,
        payload: {
            images,
        }
    }
}

export const set_memorial_config_friends = friends => {
    return {
        type: SET_MEMORIAL_CONFIG_FRIENDS,
        payload: {
            friends,
        }
    }
}

export const set_memorial_config_tributes =  tributes => {
    return {
        type: SET_MEMORIAL_CONFIG_TRIBUTES,
        payload: {
            tributes,
        }
    }
}

export const set_first_string_images_list = list => {
    return {
        type: SET_FIRST_STRING_IMAGES_LIST,
        payload: {
            list,
        }
    }
}

export const set_second_string_images_list = list => {
    return {
        type: SET_SECOND_STRING_IMAGES_LIST,
        payload: {
            list,
        }
    }
}