import { SET_MEMORIAL_EVENTS_COORDS, SET_MEMORIAL_MEMORIAL_COORDS, SET_MEMORIAL_PHOTOS_COORDS, SET_MEMORIAL_TRIBUTES_COORDS } from "./memorialpagenavigation.actions"

const initialState = {
    memorialCoords: 0,
    photosCoords: 0,
    tributesCoords: 0,
    eventsCoords: 0,
}

export const memorialPageNavigationReducer = ( state = initialState, action ) => {
    switch( action.type ) {
        case SET_MEMORIAL_MEMORIAL_COORDS:
            return {
                ...state,
                memorialCoords: action.payload.coords,
            }
        case SET_MEMORIAL_PHOTOS_COORDS:
            return {
                ...state,
                photosCoords: action.payload.coords,
            }
        case SET_MEMORIAL_TRIBUTES_COORDS:
            return {
                ...state,
                tributesCoords: action.payload.coords,
            }
        case SET_MEMORIAL_EVENTS_COORDS:
            return {
                ...state,
                eventsCoords: action.payload.coords,
            }
        default: return state
    }
}