import { show_to_atribute_modal, set_is_cookie, set_is_files_loading_popup, set_is_film_modal, set_is_friend_modal, set_is_friend_popup_modal, set_is_galery, set_is_modal_with_checkbox, set_is_profile_menu, show_add_photo_to_galery_modal, show_error_modal, show_for_confirm_modal, show_logout_modal, show_qr_modal, show_reinvite_modal, show_success_modal, show_to_create_memopage_modal, show_to_payments_modal, show_warrning_modal, toggleInviteModal } from "./modals.actions"
import { set_error_text, set_for_confirm_text, set_modal_with_checkbox_text, set_success_text, set_to_payment_modal_text, set_warrning_text } from "../MessageToModal/messageToModal.actions"

const checkParameters = ( prop, text, dispatch, modalFunc, textFunc ) => {
    if ( !prop || !text ) {
        dispatch(modalFunc(false))
        dispatch(textFunc(""))
        return        
    }

    
    if ( prop && text ) {
        
        dispatch(modalFunc(true))
        dispatch(textFunc(text))
        
        setTimeout(() => {
            dispatch(modalFunc(false))
        }, 4000)
    }  
}

export const renderSuccessModal = ( prop, text ) => dispatch => checkParameters(prop, text, dispatch, show_success_modal, set_success_text)

export const renderErrorModal = ( prop, text ) => dispatch => checkParameters(prop, text, dispatch, show_error_modal, set_error_text)

export const renderWarrningModal = ( prop, text ) => dispatch => checkParameters(prop, text, dispatch, show_warrning_modal, set_warrning_text)

// export const renderForConfirmModal = ( prop, text ) => dispatch => checkParameters(prop, text, dispatch, show_for_confirm_modal, set_for_confirm_text)

export const renderForConfirmModal = (prop) => dispatch => dispatch(show_for_confirm_modal(prop))

export const renderToPaymentModal = ( prop, text ) => dispatch => checkParameters(prop, text, dispatch, show_to_payments_modal, set_to_payment_modal_text)

export const renderModalWithCheckbox = ( prop, text ) => dispatch => checkParameters(prop, text, dispatch, set_is_modal_with_checkbox, set_modal_with_checkbox_text)

export const renderLogoutModal = prop => dispatch => dispatch(show_logout_modal(prop))

export const renderQRModal = prop => dispatch => dispatch(show_qr_modal(prop))

export const renderInviteModal = prop => dispatch => dispatch(toggleInviteModal(prop))

export const renderGaleryModal = prop => dispatch => dispatch(set_is_galery(prop))

export const renderFriendsModal = prop => dispatch => dispatch(set_is_friend_modal(prop))

export const renderFriendPopUpModal = prop => dispatch => dispatch(set_is_friend_popup_modal(prop))

export const renderCookieModal = prop => dispatch => dispatch(set_is_cookie(prop))

export const renderFilmModal = prop => dispatch => dispatch(set_is_film_modal(prop))

export const renderToCreateMemoPageModal = prop => dispatch => dispatch(show_to_create_memopage_modal(prop))

export const renderAddPhotoToGaleryModal = prop => dispatch => dispatch(show_add_photo_to_galery_modal(prop))

export const renderAtributeModal = prop => dispatch => dispatch(show_to_atribute_modal(prop))

export const renderProfileMenu = prop => dispatch => dispatch(set_is_profile_menu(prop))

export const renderReinviteModal = prop => dispatch => dispatch(show_reinvite_modal(prop))

export const renderFilesLoadingPopUp = prop => dispatch => dispatch(set_is_files_loading_popup(prop))