export const SET_ACTIVE_MEMORIAL_ID = "NOTIFICATIONS/SET_ACTIVE_MEMORIAL_ID"
export const SET_MEMORIALS_NOTIFICATIONS_TABLE = "NOTIFICATIONS/SET_MEMORIALS_NOTIFICATIONS_TABLE"

export const set_active_memorial_id = id => {
    return {
        type: SET_ACTIVE_MEMORIAL_ID,
        payload: {
            id,
        }
    }
}

export const set_memorials_notifications_table = data => {
    return {
        type: SET_MEMORIALS_NOTIFICATIONS_TABLE,
        payload: {
            data,
        }
    }
}