export const SET_ERROR_TEXT = "MESSAGES/SET_ERROR_TEXT"
export const SET_SUCCESS_TEXT = "MESSAGES/SET_SUCCESS_TEXT"
export const SET_WARRNING_TEXT = "MESSAGES/SET_WARRNING_TEXT"
export const SET_FOR_CONFIRM_TEXT = "MESSAGES/SET_FOR_CONFIRM_TEXT"
export const SET_TO_PAYMENT_MODAL_TEXT = "MESSAGES/SET_TO_PAYMENT_MODAL_TEXT"
export const SET_MODAL_WITH_CHECKBOX_TEXT = "MESSAGES/SET_MODAL_WITH_CHECKBOX_TEXT"

export const set_error_text = errorText => {
    return {
        type: SET_ERROR_TEXT,
        payload: {
            errorText,
        }
    }
}

export const set_success_text = successText => {
    return {
        type: SET_SUCCESS_TEXT,
        payload: {
            successText,
        }
    }
}

export const set_warrning_text = warrningText => {
    return {
        type: SET_WARRNING_TEXT,
        payload: {
            warrningText,
        }
    }
}

export const set_for_confirm_text = text => {
    return {
        type: SET_FOR_CONFIRM_TEXT,
        payload: {
            text,
        }
    }
}

export const set_to_payment_modal_text = text => {
    return {
        type: SET_TO_PAYMENT_MODAL_TEXT,
        payload: {
            text,
        }
    }
}

export const set_modal_with_checkbox_text = text => {
    return {
        type: SET_MODAL_WITH_CHECKBOX_TEXT,
        payload: {
            text,
        }
    }
}