export const SET_AUTH = "AUTH/SET_AUTH"
export const SET_USER_ID = "AUTH/SET_USER_ID"
export const SET_USER_TOKEN = "AUTH/SET_USER_TOKEN"
export const SET_INVITED_ID = "AUTH/SET_INVITED_ID"
export const SET_AUTH_EMAIL = "AUTH/SET_AUTH_EMAIL"
export const SET_AUTH_PASSWORD = "AUTH/SET_AUTH_PASSWORD"
export const SET_IS_LOGINNED = "AUTH/SET_IS_LOGINNED"

export const set_auth = isLog => {
    return {
        type: SET_AUTH,
        payload: {
            isLog,
        }
    }
}

export const set_user_token = token => {
    return {
        type: SET_USER_TOKEN,
        payload: {
            token
        }
    }
}

export const set_user_id = id => {
    return {
        type: SET_USER_ID,
        payload: {
            id,
        }
    }
}

export const set_invited_id = id => {
    return {
        type: SET_INVITED_ID,
        payload: {
            id,
        }
    }
}

export const set_auth_email = email => {
    return {
        type: SET_AUTH_EMAIL,
        payload: {
            email,
        },
    }
}

export const set_auth_password = password => {
    return {
        type: SET_AUTH_PASSWORD,
        payload: {
            password,
        }
    }
}