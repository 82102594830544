import { SET_AUTH, SET_AUTH_EMAIL, SET_AUTH_PASSWORD, SET_INVITED_ID, SET_USER_ID, SET_USER_TOKEN } from "./auth.actions";

const initialState = {
    isAuth: false,
    userToken: "",
    userId: 0,
    invitedId: 0,
    email: "",
    password: "",
}

export const authReducer = ( state = initialState, action ) => {
    switch( action.type ) {
        case SET_AUTH:
            return {
                ...state,
                isAuth: action.payload.isLog,
            }
        case SET_USER_TOKEN:
            return {
                ...state,
                userToken: action.payload.token,
            }
        case SET_USER_ID:
            return {
                ...state,
                userId: action.payload.id,
            }
        case SET_INVITED_ID:
            return {
                ...state,
                invitedId: action.payload.id,
            }
        case SET_AUTH_EMAIL:
            return {
                ...state,
                email: action.payload.email,
            }
        case SET_AUTH_PASSWORD:
        return {
            ...state,
            password: action.payload.password,
        }
        default: return state
    }
}