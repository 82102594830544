import { SET_IS_TRIBUTE_SENDED, SHOW_TRIBUTES_MODAL, SET_MEMORIAL_TRIBUTES_LIST, SET_TRIBUTE_TEXT, SET_TRIBUTE_INPUT_LINES_COUNT } from "./memorialTributes.actions"

const initialState = {
    tributesList: null,
    tributeText: "",
    isTributeSended: true,
    tributeLinesCount: 40,
    isTributesModal: false
}

export const memorialTributesReducer = ( state = initialState, action ) => {
    switch( action.type ) {
        case SET_MEMORIAL_TRIBUTES_LIST:
            return {
                ...state,
                tributesList: action.payload.list
            }
        case SET_TRIBUTE_TEXT:
            return {
                ...state,
                tributeText: action.payload.text,
            }
        case SET_IS_TRIBUTE_SENDED:
            return {
                ...state,
                isTributeSended: action.payload.prop,
            }
        case SET_TRIBUTE_INPUT_LINES_COUNT:
            return {
                ...state,
                tributeLinesCount: action.payload.number,
            }
        case SHOW_TRIBUTES_MODAL:
            return {
                ...state,
                isTributesModal: action.payload.prop,
            }
        default: return state
    }
}