export const SET_CURRENT_PROFILE_PAGE = "PROFILE/SET_CURRENT_PROFILE_PAGE"
export const SET_PRODUCT = "PROFILE/SET_PRODUCT"

export const set_current_profile_page = page => {
    return {
        type: SET_CURRENT_PROFILE_PAGE,
        payload: {
            page,
        }
    }
}

export const setProduct = product => {
    return {
        type: SET_PRODUCT,
        payload: {
            product,
        }
    }
}