import { SET_IS_MM_PHOTH_CHANGED, SET_IS_UNPAID_MEMOMOVIE, SET_IS_USER_INVITED_TO_MEMOMOVIE, SET_LAST_SIX_MEMORIALS, SET_MEMORIAL_DATA, SET_MIRROR_DATA } from "./mirrordata.actions"

const initialState = {
    mirrorData: null,
    memorialData: null,
    lastSixMemorialsList: null,
    isUserInvitedToMemoMovie: true, 
    isUnpaid: null,
    isMMPC: false
}

export const mirrorDataReducer = ( state = initialState, action ) => {
    switch( action.type ) {
        case SET_MIRROR_DATA:
            return {
                ...state,
                mirrorData: action.payload.data
            }
        case SET_MEMORIAL_DATA:
            return {
                ...state,
                memorialData: action.payload.data
            }
        case SET_IS_USER_INVITED_TO_MEMOMOVIE:
            return {
                ...state,
                isUserInvitedToMemoMovie: action.payload.prop,
            }
        case SET_IS_UNPAID_MEMOMOVIE:
            return {
                ...state,
                isUnpaid: action.payload.prop,
            }
        case SET_IS_MM_PHOTH_CHANGED:
            const { prop } = action.payload
            const isMMO = JSON.parse(localStorage.getItem("isMemoMovieOpen"))
            if ( isMMO && prop ) {
                localStorage.setItem(`isMMPC-${isMMO}`, JSON.stringify(true))
            }
            return {
                ...state,
                isMMPC: prop
            }
        default: return state
    }
}