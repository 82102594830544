export const SET_MEMORIAL_FRIENDS_LIST = "MEMORIAL/SET_MEMORIAL_FRIENDS_LIST"
export const SET_IS_INVITED_SUBSRIBED = "MEMORIAL/SET_IS_INVITED_SUBSRIBED"
export const SET_IS_INVITED_FRIEND = "MEMORIAL/SET_IS_INVITED_FRIEND"
export const SET_IS_UNLOGGINED_INVITED = "MEMORIAL/SET_IS_UNLOGGINED_INVITED"
export const SET_INVITE_LINK = "MEMORIAL/SET_INVITE_LINK"
export const SET_IS_SUBSCRIBED = "MEMORIAL/SET_IS_SUBSCRIBED"
export const SET_IS_MEMORIAL_OPEN = "MEMORIAL/SET_IS_MEMORIAL_OPEN"
export const SET_IS_FRIEND = "MEMORIAL/SET_IS_FRIEND"
export const SET_IS_FRIEND_ARE_SUBSCRIBER = "MEMORIAL/SET_IS_FRIEND_ARE_SUBSCRIBER"

export const set_memorial_friends_list = list => {
    return {
        type: SET_MEMORIAL_FRIENDS_LIST,
        payload: {
            list,
        }
    }
}

export const set_is_invited_subscribed = prop => {
    return {
        type: SET_IS_INVITED_SUBSRIBED,
        payload: {
            prop,
        }
    }
}

export const set_is_invited_friend = prop => {
    return {
        type: SET_IS_INVITED_FRIEND,
        payload: {
            prop,
        }
    }
}

export const set_is_unloggined_invited = prop => {
    return {
        type: SET_IS_UNLOGGINED_INVITED,
        payload: {
            prop,
        }
    }
}

export const set_invite_link = link => {
    return {
        type: SET_INVITE_LINK,
        payload: {
            link,
        }
    }
}

export const set_is_subscribed = prop => {
    return {
        type: SET_IS_SUBSCRIBED,
        payload: {
            prop,
        }
    }
}

export const set_is_memorial_open = prop => {
    return {
        type: SET_IS_MEMORIAL_OPEN,
        payload: {
            prop,
        }
    }
}

export const set_is_friend = prop => {
    return {
        type: SET_IS_FRIEND,
        payload: {
            prop
        }
    }
}

export const set_is_friend_are_subsriber = prop => {
    return {
        type: SET_IS_FRIEND_ARE_SUBSCRIBER,
        payload: {
            prop,
        }
    }
}