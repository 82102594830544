import React from "react"


//Components
import LittleSpinner from "../../ModalsItems/LittleSpinner/LittleSpinner"

const HeaderLoadingSpinner = () => {
    return(
        <li className="profilemenu headermenutext headeravatar">
            <LittleSpinner />
        </li>
    )
}

export default HeaderLoadingSpinner