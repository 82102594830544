//react
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";

//Components
import App from "./components/App";

//Mirror components
import reportWebVitals from "./MemoMovie/reportWebVitals";

//Redux
import { Provider } from "react-redux";
import { store } from "./redux/store";
import { initAxios } from "./utils/axiosApi";
import "./styles/index.scss";

//redux-devtools
import { disableReactDevTools } from "@fvilers/disable-react-devtools";

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Provider store={store}>
        <App />
      </Provider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);

initAxios();
disableReactDevTools();
reportWebVitals();
