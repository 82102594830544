import { getLastSixMemorialsList } from "../../hooks/useApi"
import { set_last_six_memorials } from "../Mirror/MirrorData/mirrordata.actions"

export const SET_HOME_SIX_MEMOPAGES = "HOMEPAGE/SET_HOME_SIX_MEMOPAGES"

export const set_home_six_memopages = list => {
    return {
        type: SET_HOME_SIX_MEMOPAGES,
        payload: {
            list
        }
    }
}

export const set_home_memopages = () =>
    async dispatch => {
        return await getLastSixMemorialsList()
            .then( res => {
                if ( res.status === 200 ) {
                    dispatch(set_home_six_memopages(res.data))
                }
            })
            .catch(err => set_last_six_memorials(null))
    }