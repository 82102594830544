import { SET_INVITE_LINK, SET_IS_FRIEND, SET_IS_FRIEND_ARE_SUBSCRIBER, SET_IS_INVITED_FRIEND, SET_IS_INVITED_SUBSRIBED, SET_IS_MEMORIAL_OPEN, SET_IS_SUBSCRIBED, SET_IS_UNLOGGINED_INVITED, SET_MEMORIAL_FRIENDS_LIST } from "./memorialfriends.actions"

const initialState = {
    memorialFriendsList: null,
    isInvitedSubscribed: false,
    isUnlogginedInvited: false,
    isSubscribed: false,
    isFriend: false,
    isFriendAreSubscriber: false,
    inviteLink: "",
    isMemorialOpen: null,
}

export const memorialFriendsReducer = ( state = initialState, action ) => {
    switch( action.type ) {
        case SET_MEMORIAL_FRIENDS_LIST:
            return {
                ...state,
                memorialFriendsList: action.payload.list,
            }
        case SET_IS_INVITED_SUBSRIBED:
            return {
                ...state,
                isInvitedSubscribed: action.payload.prop,
            }
        case SET_IS_UNLOGGINED_INVITED:
            return {
                ...state,
                isUnlogginedInvited: action.payload.prop,
            }
        case SET_INVITE_LINK:
            return {
                ...state,
                inviteLink: action.payload.link
            }
        case SET_IS_SUBSCRIBED:
            return {
                ...state,
                isSubscribed: action.payload.prop,
            }
        case SET_IS_MEMORIAL_OPEN:
            return {
                ...state,
                isMemorialOpen: action.payload.prop,
            }
        case SET_IS_FRIEND:
            return {
                ...state,
                isFriend: action.payload.prop
            }
        case SET_IS_FRIEND_ARE_SUBSCRIBER:
            return {
                ...state,
                isFriendAreSubscriber: action.payload.prop,
            }
        default: return state
    }
}