import { SET_PAGE_TITLE } from "./appNavigation.actions"

const intialState = {
    pageTitle: "",
}

export const appNavigationReducer = ( state = intialState, action ) => {
    switch(action.type) {
        case SET_PAGE_TITLE:
            return {
                ...state,
                pageTitle: action.payload.title,
            }
        default: return state
    }
}