import axios from "axios"
import moment from "moment"

export const getAuthToken = async data => {
    return await axios.post( "/api/token", data )
}

export const getLastSixMemorialsList = async () => {
    return axios.get( "/api/memorial/last6" )
}

export const getUserData = async id => {
    return axios.get(`/api/users/profile/${id}`)
}

export const getMemorialDataBySeo = async seo => {
    return await axios.get(`api/memorial/seo/${seo}`)
}

export const getMemorialData = async id => {
    return await axios.get(`/api/memorial/${id}`)
}

export const deleteMemorial = async id => {
    return await axios.get( `/api/memorial/delete/${id}` )
}

export const getMemorialFriendsList = async id => {
    return await axios.get( `/api/friends/list/${id}` )
}

export const addMemorialFriend = async id => {
    return await axios.get(`api/friends/add/${id}`)
}

export const deleteMemorialFriend = async ( memorialId, userId ) => {
    return await axios.get(`/api/friends/delete/${memorialId}/${userId}`)
}

export const getMemorialImagesList = async id => {
    return await axios.get( `/api/memorial/images/${id}` )
}

export const getMemorialTributesList = async id => {
    return await axios.get( `/api/tributes/list/${id}` )
}

export const getMemorialsList = async id => {
    return await axios.get(`/api/memorial/list/${id}`)
}

export const getMemorialBackImages = async () => {
    return await axios.get(`api/bgimages/list`)
}

export const postCardImage = async imgdata => {
    return await axios.post( "/api/memorial/cardphoto", imgdata )
}

export const getBase64ByLink = async link => {
    return await axios.get(`/api/base64image?link=${link}`)
}

export const deleteMemoPageImage = async imgId => {
    return await axios.get( `/api/memorial/delimage/${imgId}` )
}

export const sortMemoPageImage = async data => {
    return await axios.post( `api/memorial/imagesorder`, data )
}

export const postMemoPageImage = async (id, formData) => {
    return await axios.post(`/api/memorial/addimage/${id}`, formData)
}

export const postMainImage = async imgdata => {
    return await axios.post( "/api/memorial/poster", imgdata )
}

export const changePaymentPlan = async (id, paymentPlan) => {
    return await axios.get(`/api/memorial/setplan/${id}/${paymentPlan}`)
}

export const setPaidPediod = async (id, paymentPlan, to) => {
    return await axios.get(`api/memorial/setpayment/${id}/${paymentPlan === 3 ? moment().format("YYYY-MM-DD") : moment(to).format("YYYY-MM-DD") }`)
}

export const postGaleryImage = async (memorialId, formdata) => {
    return await axios.post( `/api/memorial/addimage/${memorialId}`, formdata )
}

export const updateMemorialData = async data => {
    return await axios.post("/api/memorial/update", data)
}

export const getTributesList = async memorialId => {
    return await axios.get(`/api/tributes/list/${memorialId}`)
}

export const postNewPassword = async newPassword => {
    return await axios.post( "/api/users/password", { "password": newPassword } )
}

export const postNewSettingsData = async newSettingsData => {
    return await axios.post( "/api/users/profile", newSettingsData )
}

export const postUserAvatar = async avatarData => {
    return await axios.post( "/api/users/addavatar", avatarData )
}

export const getPersonalInfo = async id => {
    return await axios.get(`/api/users/userinfo/${id}`)
}

export const addInvitedFriend = async memorialId => {
    return await axios.get(`api/friends/addinvited/${memorialId}`)
}

export const addMemorialCoowner = async ( memorialId, userId ) => {
    return await axios.get(`/api/coowner/add/${memorialId}/${userId}`)
}

export const removeMemorialCoowner = async ( memorialId, userId ) => {
    return await axios.get(`/api/coowner/remove/${memorialId}/${userId}`)
}

export const hasNewNotify = async () => {
    return await axios.get(`/api/notify/hasnew`)
}

export const getNewNotifysList = async () => {
    return await axios.get(`/api/notify/newList`)
}

export const markAllNotifysReaded = async () => {
    return await axios.get(`/api/notify/markreaded`)
}

export const seachNotifysList = async searchText => {
    return await axios.get(`/api/notify/searchlist?search=${searchText}`)
}

export const confirmNotify = async id => {
    return await axios.get(`/api/notify/confirm/${id}`)
}

export const declineNotify = async id => {
    return await axios.get(`/api/notify/decline/${id}`)
}

export const registerNewUser = async data => {
    return await axios.post("/api/users/signup", data)
}

export const confirmUserFromEmail = async hash => {
    return await axios.get(`/api/users/confirmreg?hash=${hash}`)
}

export const contactus = async data => {
    return await axios.post("api/contactus", data)
}

export const recoverypass = async emailData => {
    return await axios.post(`/api/recoverypass`, emailData)
}

export const sendPayment = async data => {
    return await axios.post("/api/sendemail", data)
}
export const facebookToken = async facebookId => {
    return await axios.get(`/api/token/fb/${facebookId}`)
}

export const signUpFacebook = async data => {
    return await axios.post("/api/signup/facebook", data)
}

export const googleToken = async googleId => {
    return await axios.get(`/api/token/google/${googleId}`)
}

export const googleSignUp = async data => {
    return await axios.post("/api/signup/google", data)
}


export const deleteTribute = async id => {
    return await axios.get(`/api/tributes/delete/${id}`)
}

export const addTribute = async tributeData => {
    return await axios.post("/api/tributes/add", tributeData)
}

export const interactTribute = async ( id, method ) => {
   return await axios.get( `/api/tributes/publish/${id}/${method}` )
}

export const searchMemoPage = async data => {
    return await axios.post( "/api/memorial/search", data )
}

export const getMemoMovieData = async memorialId => {
    return await axios.get(`/api/mirror/open/${memorialId}`)
}

export const getMemoMovieUserVideoData = async ( mirrorId, userId ) => {
    return await axios.get(`/api/uservideo/${mirrorId}/${userId}`)
}

export const setMemoMovieUserVideoData = async userVideoData => {
    return await axios.post(`/api/uservideo/update`, userVideoData)
}