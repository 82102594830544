import { SET_HOME_SIX_MEMOPAGES } from "./homePage.action"

const initialState = {
    memopagesList: null,
}

export const homePageReducer = ( state = initialState, action ) => {
    switch( action.type ) {
        case SET_HOME_SIX_MEMOPAGES:
            return {
                ...state,
                memopagesList: action.payload.list,
            }
        default: return state
    }
}