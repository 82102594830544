import { SET_SERVER_ERROR_MESSAGE, SET_SIGN_UP_CONFIRM_PASSWORD, SET_SIGN_UP_EMAIL, SET_SIGN_UP_NAME, SET_SIGN_UP_PASSWORD, SET_SUCCESS_SIGNED } from "./sign_up.actions"

const initialState = {
    email: "",
    name: "",
    password: "",
    confirmPassword: "",
    isSuccessSigned: false,
    serverErrorMessage: "",
}

export const signUpReducer = ( state = initialState, action ) => {
    switch( action.type ) {
        case SET_SIGN_UP_EMAIL:
            return {
                ...state,
                email: action.payload.email,
            }
        case SET_SIGN_UP_NAME:
            return {
                ...state,
                name: action.payload.name,
            }
        case SET_SIGN_UP_PASSWORD:
            return {
                ...state,
                password: action.payload.password,
            }
        case SET_SIGN_UP_CONFIRM_PASSWORD:
            return {
                ...state,
                confirmPassword: action.payload.password
            }
        case SET_SUCCESS_SIGNED:
            return {
                ...state,
                isSuccessSigned: action.payload.prop,
            }
        default: return state
    }
}