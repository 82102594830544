export const SET_CURRENT_LAYOUT = "LAYOUTS/SET_CURRENT_LAYOUT"
export const SET_CURRENT_LAYOUT_FILTER_TYPE = "LAYOUTS/SET_CURRENT_LAYOUT_FILTER_TYPE"

export const set_current_layout = layout => {
    return {
        type: SET_CURRENT_LAYOUT,
        payload: {
            layout,
        }
    }
}

export const set_current_layout_filter_type = type => {
    return {
        type: SET_CURRENT_LAYOUT_FILTER_TYPE,
        payload: {
            type,
        }
    }
}