import { SET_MEMOMOVIE_CONFIG_CAN_BE_OPENNED, SET_MEMOMOVIE_CONFIG_DATA, SET_MEMOMOVIE_CONFIG_MEMOPAGE_DATA, SET_MEMOMOVIE_CONFIG_USER_DATA } from "./memomovie.actions"

const initialState = {
    config: {
        user: null,
        memorial: null,
        mirror: null,
        userVideoData: null,
        firstLoad: false,
        render: 0,
        hasAdditionalInfoChanged: false,
        isCompiling: false,
        filmDuration: 0,
        filmDurationLimit: 0,
        videoDurationLimit: 0,
        isFilmDurationExceedsLimit: false,
        canBeOpened: false,
        videoState: null,
        fullVideoList: [],
    },
}

export const memomovieReducer = ( state = initialState, action ) => {
    switch (action.type) {
        case SET_MEMOMOVIE_CONFIG_DATA:
            return {
                ...state,
                config: {
                    ...state.config,
                    mirror: action.payload.data,
                }
            }
        case SET_MEMOMOVIE_CONFIG_CAN_BE_OPENNED:
            return {
                ...state,
                config: {
                    ...state.config,
                    canBeOpened: action.payload.prop,
                }
            }
        case SET_MEMOMOVIE_CONFIG_MEMOPAGE_DATA:
            return {
                ...state,
                config: {
                    ...state.config,
                    memorial: action.payload.data,
                }
            }
        case SET_MEMOMOVIE_CONFIG_USER_DATA:
            return {
                ...state,
                config: {
                    ...state.config,
                    user: action.payload.data,
                }
            }
        default: return state
    }
}