import {
  SET_EDITOR,
  SET_IS_CREATED,
  SET_NEW_MEMORIAL_BORN_DATE,
  SET_NEW_MEMORIAL_PASSED_DATE,
  SET_NEW_MEMORIAL_BORN_DAY,
  SET_NEW_MEMORIAL_BORN_MONTH,
  SET_NEW_MEMORIAL_BORN_YEAR,
  SET_NEW_MEMORIAL_GIVEN_NAME,
  SET_NEW_MEMORIAL_OCUPATION,
  SET_NEW_MEMORIAL_PASSED_AWAY_DAY,
  SET_NEW_MEMORIAL_PASSED_AWAY_MONTH,
  SET_NEW_MEMORIAL_PASSED_AWAY_YEAR,
  SET_NEW_MEMORIAL_SURNAME,
  SET_NEW_MEMORIAL_SECOND_NAME,
  SET_NEW_MEMO_ID,
  SET_POSTER_IMG,
  SET_NEW_MEMORIAL_EVENT_NAME,
  SET_NEW_MEMORIAL_EVENT_DATE,
} from "./newmemorial.actions";

const initialState = {
  givenName: "",
  surName: "",
  secondName: "",
  bornDate: "",
  bornDay: "",
  bornMonth: "",
  passedDate: "",
  bornYear: "",
  passedDay: "",
  passedMonth: "",
  passedYear: "",
  ocupation: "",
  newMemoId: 0,
  isCreated: false,
  eventName: "",
  eventDate: "",
};

export const newMemorialReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_NEW_MEMORIAL_EVENT_NAME:
      return {
        ...state,
        eventName: action.payload.eventName,
      };
    case SET_NEW_MEMORIAL_EVENT_DATE:
      return {
        ...state,
        eventDate: action.payload.eventDate,
      };

    case SET_NEW_MEMORIAL_GIVEN_NAME:
      return {
        ...state,
        givenName: action.payload.givenName,
      };
    case SET_NEW_MEMORIAL_SURNAME:
      return {
        ...state,
        surName: action.payload.surName,
      };
    case SET_NEW_MEMORIAL_SECOND_NAME:
      return {
        ...state,
        secondName: action.payload.secondName,
      };
    case SET_NEW_MEMORIAL_BORN_DATE:
      return {
        ...state,
        bornDate: action.payload.bornDate,
      };
    case SET_NEW_MEMORIAL_PASSED_DATE:
      return {
        ...state,
        passedDate: action.payload.passedDate,
      };
    case SET_NEW_MEMORIAL_BORN_DAY:
      return {
        ...state,
        bornDay: action.payload.day,
      };
    case SET_NEW_MEMORIAL_BORN_MONTH:
      return {
        ...state,
        bornMonth: action.payload.month,
      };
    case SET_NEW_MEMORIAL_BORN_YEAR:
      return {
        ...state,
        bornYear: action.payload.year,
      };
    case SET_NEW_MEMORIAL_PASSED_AWAY_DAY:
      return {
        ...state,
        passedDay: action.payload.day,
      };
    case SET_NEW_MEMORIAL_PASSED_AWAY_MONTH:
      return {
        ...state,
        passedMonth: action.payload.month,
      };
    case SET_NEW_MEMORIAL_PASSED_AWAY_YEAR:
      return {
        ...state,
        passedYear: action.payload.year,
      };
    case SET_NEW_MEMORIAL_OCUPATION:
      return {
        ...state,
        ocupation: action.payload.ocupation,
      };
    case SET_NEW_MEMO_ID:
      return {
        ...state,
        newMemoId: action.payload.id,
      };
    case SET_IS_CREATED:
      return {
        ...state,
        isCreated: action.payload.prop,
      };
    default:
      return state;
  }
};
