export const SET_MEMORIAL_TRIBUTES_LIST = "MEMORIAL/SET_MEMORIAL_TRIBUTES_LIST"
export const SET_TRIBUTE_TEXT = "MEMORIAL/SET_TRIBUTE_TEXT"
export const SET_IS_TRIBUTE_SENDED = "MEMORIAL/SET_IS_TRIBUTE_SENDED"
export const SET_TRIBUTE_INPUT_LINES_COUNT = "MEMORIAL/SET_TRIBUTE_INPUT_LINES_COUNT"
export const SHOW_TRIBUTES_MODAL = "MEMORIAL/SHOW_TRIBUTES_MODAL"


export const set_memorial_tributes_list = list => {
    return {
        type: SET_MEMORIAL_TRIBUTES_LIST,
        payload: {
            list,
        }
    }
}

export const show_tributes_modal = prop => {
    return {
        type: SHOW_TRIBUTES_MODAL,
        payload: {
            prop,
        }
    }
}

export const set_tribute_text = text => {
    return {
        type: SET_TRIBUTE_TEXT,
        payload: {
            text,
        }
    }
}

export const set_is_tribute_sended = prop => {
    return {
        type: SET_IS_TRIBUTE_SENDED,
        payload: {
            prop,
        }
    }
}

export const set_tribute_input_lines_count = number => {
    return {
        type: SET_TRIBUTE_INPUT_LINES_COUNT,
        payload: {
            number,
        }
    }
}