import { SET_MEMORIAL_SEARCH_BORN_DATE, SET_MEMORIAL_SEARCH_NAME, SET_MEMORIAL_SEARCH_OCUPATION, SET_MEMORIAL_SEARCH_PASSED_AWAY_DATE  } from "./memorialsearchform.actions"

const initialState = {
    name: "",
    bornDate: "",
    passedDate: "",
    ocupation: "",
}

export const memorialSearchFormReducer = ( state = initialState, action ) => {
    switch( action.type ) {
        case SET_MEMORIAL_SEARCH_NAME:
            return {
                ...state,
                name: action.payload.name
            }
        case SET_MEMORIAL_SEARCH_OCUPATION:
            return {
                ...state,
                ocupation: action.payload.ocupation
            }
        case SET_MEMORIAL_SEARCH_BORN_DATE:
            return {
                ...state,
                bornDate: action.payload.date,
            }
        case SET_MEMORIAL_SEARCH_PASSED_AWAY_DATE:
            return {
                ...state,
                passedDate: action.payload.date
            }
        default: return state
    }
}