// Memopage images size limits
export const IMAGE_SIZE_LIMIT = 5 * 1024;
export const COVER_IMAGE_SIZE_LIMIT = 2 * 1024;
export const MAIN_IMAGE_SIZE_LIMIT = 3.5 * 1024;

// Prod
export const IS_PRODUCTION = window.location.host === "life-trace.online";

//RegExp
export const emailRegExp =
/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
export const nameRegExp = /^[a-zA-Z'][a-zA-Z-' ]+[a-zA-Z']?$/;
export const messageRegExp =
  /^([а-яА-Яa-zA-ZіІїЇґҐєЄ0-9_-])*([а-яА-Яa-zA-ZіІїЇґҐєЄ0-9_-])*$/;

export const ifahfpiawfawpifhjawp9ofawjfawf =
  "fb1204f5e29ba3f6e7cd69059303168b";
export const ajfawopjfawofjkwafjkmawfopawjkfaw =
  "275d796e9c66b9af3d3f2bd9da555e46";

//Links

export const NAVBAR_LINKS = [
  { text: "MemoPages", href: "/memopages/list" },
  // { text: "MemoMovie", href: "/memomovie-advertising" },
  // {
  //   text: "MemoNFT",
  //   href: "/memonft",
  // },
  { text: "FAQ", href: "/faq" },
  { text: "About", href: "/about_us" },
];

export const FOOTER_LINKS_LEFT = [
  { text: "About Us", href: "/about_us" },
  { text: "MemoPages", href: "/memopages/list" },
  {
    text: "Pricing",
    href: "/pricing",
  },
  // { text: "Partnership", href: "/partnership" },
];

export const FOOTER_LINKS_RIGHT = [
  { text: "Terms Of Use", href: "/terms_of_use" },
  { text: "Privacy Policy", href: "/privacy-policy" },
  {
    text: "FAQ",
    href: "/faq",
  },
  { text: "Contact Us", href: "/contact_us" },
];
