import React from "react"
import "./Spinner.scss"

const Spinner = ({ isBackground }) => {
    const spinnerBackground = isBackground ? { backgroundColor: "white" } : { backgroundColor: "rgba(255,255,255,0.001)" }

    return(
        <div className="overflowspinner" style={spinnerBackground}>
            <div className="overflowspinner-spinner"></div>
        </div>
    )
}

export default Spinner