export const SET_IS_PROFILE_MENU = "HEADER/SET_IS_PROFILE_MENU"
export const SET_IS_NOTIFICATIONS_MENU = "HEADER_SET_IS_NOTIFICATIONS_MENU"

export const set_is_profile_menu = prop => {
    return {
        type: SET_IS_PROFILE_MENU,
        payload: {
            prop,
        }
    }
}

export const set_is_notifications_menu = prop => {
    return {
        type: SET_IS_NOTIFICATIONS_MENU,
        payload: {
            prop,
        }
    }
}