import {
  SET_EXCERPT,
  SET_FULL_DESCRIPTION,
  SET_GEOTAG,
  SET_MEMORIAL_IMAGES,
  SET_MEMORIAL_ID,
  SET_SHORT_DESCRIPTION,
  SET_POSTER_CROPPER_IMG,
  SET_POSTER_IMG,
  SET_MEMORIAL_STATUS,
  SET_MEMORIAL_TARIF,
  SET_MEMORIAL_BACK_IMAGES_LIST,
  SET_MEMORIAL_BACK_IMAGE,
  SET_MEMORIAL_ADDRESS,
  SET_POSTER_IMAGE_ID,
  SET_CARD_POSTER_IMG,
  SET_CARD_POSTER_CROPPER_IMG,
  SET_IS_CARD_POSTER_SAVED,
  SET_LOADED_IMAGES_FILES,
  SET_IS_STATUS_CHANGED,
  SET_LOADED_IMAGES,
  SET_CAN_RELOAD_MEMORIAL_DATA,
  SET_IS_POSTER_SAVED,
  SET_IMAGES_VALIDATOR_ARRAY,
  SET_IS_IMAGES_SENDED,
  SET_PAYMENT_VALIDATION_VALUE,
  SET_IMAGES_STRINGS,
  SET_IS_UPDATED,
  SET_MEMORIAL_COOWNER_ID,
  SET_LOADED_FILE_INDEX,
  SET_EVENT_DATE,
  SET_EVENT_NAME,
} from "./editmemorial.actions";

const initialState = {
  memorialId: null,
  excerpt: "",
  shortDescription: "",
  fullDescription: "",
  geotag: null,
  memorialImages: [],
  posterCropperImg: "",
  cardPosterCropperImg: "",
  cardPosterImg: "",
  posterImg: "",
  memorialAddress: "",
  memorialStatus: 1,
  tarif: 1,
  posterImageId: 0,
  loadedImagesFiles: [],
  loadedImages: [],
  memorialBackImagesList: null,
  memorialBackImage: null,
  isPosterSaved: false,
  isCardPosterSaved: false,
  isStatusChanged: false,
  canReloadMemorialData: false,
  imagesValidatorArray: [],
  isImagesSended: true,
  paymentValidationValue: 0,
  imagesStrings: [],
  isUpdated: false,
  memorialCoownerId: 0,
  loadedFileIndex: 0,
  eventName: "",
  eventDate: null,
};

export const editMemorialReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_EVENT_DATE:
      return {
        ...state,
        eventDate: action.payload.eventDate,
      };

    case SET_EVENT_NAME:
      return {
        ...state,
        eventName: action.payload.eventName,
      };

    case SET_MEMORIAL_ID:
      return {
        ...state,
        memorialId: action.payload.id,
      };
    case SET_EXCERPT:
      return {
        ...state,
        excerpt: action.payload.text,
      };
    case SET_SHORT_DESCRIPTION:
      return {
        ...state,
        shortDescription: action.payload.text,
      };
    case SET_FULL_DESCRIPTION:
      return {
        ...state,
        fullDescription: action.payload.text,
      };
    case SET_GEOTAG:
      return {
        ...state,
        geotag: action.payload.geotag,
      };
    case SET_MEMORIAL_IMAGES:
      return {
        ...state,
        memorialImages: action.payload.imagesData,
      };
    case SET_POSTER_CROPPER_IMG:
      return {
        ...state,
        posterCropperImg: action.payload.img,
      };
    case SET_CARD_POSTER_CROPPER_IMG:
      return {
        ...state,
        cardPosterCropperImg: action.payload.img,
      };
    case SET_POSTER_IMG:
      return {
        ...state,
        posterImg: action.payload.img,
      };
    case SET_CARD_POSTER_IMG:
      return {
        ...state,
        cardPosterImg: action.payload.img,
      };
    case SET_MEMORIAL_STATUS:
      return {
        ...state,
        memorialStatus: action.payload.status,
      };
    case SET_MEMORIAL_TARIF:
      return {
        ...state,
        tarif: action.payload.tarif,
      };
    case SET_MEMORIAL_BACK_IMAGES_LIST:
      return {
        ...state,
        memorialBackImagesList: action.payload.list,
      };
    case SET_MEMORIAL_BACK_IMAGE:
      return {
        ...state,
        memorialBackImage: action.payload.img,
      };
    case SET_MEMORIAL_ADDRESS:
      return {
        ...state,
        memorialAddress: action.payload.address,
      };
    case SET_POSTER_IMAGE_ID:
      return {
        ...state,
        posterImageId: action.payload.id,
      };
    case SET_IS_POSTER_SAVED:
      return {
        ...state,
        isPosterSaved: action.payload.prop,
      };
    case SET_IS_CARD_POSTER_SAVED:
      return {
        ...state,
        isCardPosterSaved: action.payload.prop,
      };
    case SET_LOADED_IMAGES_FILES:
      return {
        ...state,
        loadedImagesFiles: action.payload.list,
      };
    case SET_LOADED_IMAGES:
      return {
        ...state,
        loadedImages: action.payload.list,
      };
    case SET_IS_STATUS_CHANGED:
      return {
        ...state,
        isStatusChanged: action.payload.prop,
      };
    case SET_CAN_RELOAD_MEMORIAL_DATA:
      return {
        ...state,
        canReloadMemorialData: action.payload.prop,
      };
    case SET_IMAGES_VALIDATOR_ARRAY:
      return {
        ...state,
        imagesValidatorArray: action.payload.array,
      };
    case SET_IS_IMAGES_SENDED:
      return {
        ...state,
        isImagesSended: action.payload.prop,
      };
    case SET_PAYMENT_VALIDATION_VALUE:
      return {
        ...state,
        paymentValidationValue: action.payload.value,
      };
    case SET_IMAGES_STRINGS:
      return {
        ...state,
        imagesStrings: action.payload.number,
      };
    case SET_IS_UPDATED:
      return {
        ...state,
        isUpdated: action.payload.prop,
      };
    case SET_MEMORIAL_COOWNER_ID:
      return {
        ...state,
        memorialCoownerId: action.payload.id,
      };
    case SET_LOADED_FILE_INDEX:
      return {
        ...state,
        loadedFileIndex: action.payload.index,
      };
    default:
      return state;
  }
};
