import { getMemoMovieData, getMemoMovieUserVideoData, setMemoMovieUserVideoData } from "../../hooks/useApi"

export const SET_MEMOMOVIE_CONFIG_DATA = "MEMOMOVIE/SET_MEMOMOVIE_CONFIG_DATA"
export const SET_MEMOMOVIE_CONFIG_CAN_BE_OPENNED = "MEMOMOVIE/SET_MEMOMOVIE_CONFIG_CAN_BE_OPENNED"
export const SET_MEMOMOVIE_CONFIG_MEMOPAGE_DATA = "MEMOMOVIE/SET_MEMOMOVIE_CONFIG_MEMOPAGE_DATA"
export const SET_MEMOMOVIE_CONFIG_USER_DATA = "MEMOMOVIE/SET_MEMOMOVIE_CONFIG_USER_DATA"
export const SET_MEMOMOVIE_CONFIG_USERVIDEODATA = "MEMOMOVIE/SET_MEMOMOVIE_CONFIG_USERVIDEODATA"

export const set_memomovie_config_data = data => {
    return {
        type: SET_MEMOMOVIE_CONFIG_DATA,
        payload: {
            data
        }
    }
}

export const set_memomovie_config_can_be_openned = prop => {
    return {
        type: SET_MEMOMOVIE_CONFIG_CAN_BE_OPENNED,
        payload: {
            prop,
        }
    }
}

export const set_memomovie_config_memopage_data = data => {
    return {
        type: SET_MEMOMOVIE_CONFIG_MEMOPAGE_DATA,
        payload: {
            data
        }
    }
}

export const set_memomovie_config_user_data = data => {
    return {
        type: SET_MEMOMOVIE_CONFIG_USER_DATA,
        payload: {
            data,
        }
    }
}

export const setMemoMovieConfigData = memorialId => async dispatch => {
    return await getMemoMovieData(memorialId)
    .then(res => res.status === 200 ? dispatch(set_memomovie_config_data(res.data)) : null)
    .catch(err => dispatch(set_memomovie_config_data(null)))
}

export const set_memomovie_config_uservideodata = data => {
    return {
        type: SET_MEMOMOVIE_CONFIG_USERVIDEODATA,
        payload: {
            data,
        }
    }
}

const postNewUserVideoData = async (mirrorId, userData) => {
    const newUserVideoData = {
        relation: "",
        mirrorId: mirrorId,
        userId: userData.userId,
        userName: userData.userName,
    }

    return await setMemoMovieUserVideoData(newUserVideoData)
}

export const setMemoMovieConfigUserVideoData = ( mirrorId, userData ) => async dispatch => {
    return await getMemoMovieUserVideoData(mirrorId, userData.userid)
    .then(res => res.status === 200 ? dispatch(set_memomovie_config_uservideodata(res.data)) : null)
    .catch(async err => await postNewUserVideoData(mirrorId, userData))
}