import { SET_MEMORIAL_IMAGE_INDEX } from "./memorialimages.actions"

const initialState = {
    imageIndex: 0,
}

export const memorialImagesReducer = ( state = initialState, action ) => {
    switch( action.type ) {
        case SET_MEMORIAL_IMAGE_INDEX:
            return {
                ...state,
                imageIndex: action.payload.index,
            }
        default: return state
    }
}