//React
import React, { useEffect, useState } from "react";
import "../styles/index.scss";

//Hooks
import { useLocalStorages } from "../hooks/useLocalStorages";

//Components
import UpButton from "./ModalsItems/upButton/UpButton";
import CoockieModal from "./ModalsItems/CoockieModal/CoockieModal";
import GoogleAnatytics from "./GoogleAnalytics/GoogleAnatytics";

//Redux
import { connect } from "react-redux";
import { authSelector } from "../redux/Auth/auth.selectors.js";
import { set_auth } from "../redux/Auth/auth.actions";
import { isCookieSelector } from "../redux/Modals/modals.selectors";
import { set_is_cookie } from "../redux/Modals/modals.actions";

//Router
import Routes from "../router/Routes";
import { googleAuthInit } from "../utils/googleAuthInit";

const App = (props) => {
  const { setAuth, isCookie, setIsCookie } = props;

  const [isActive, setIsActive] = useState(false);
  const { getItem } = useLocalStorages();

  useEffect(() => {
    const ISCOOKIE = document.cookie.includes("isCookie=true");
    return ISCOOKIE ? setIsCookie(true) : setIsCookie(false);
  }, []);

  useEffect(() => {
    const activateArrowButton = () =>
      window.scrollY > 300 ? setIsActive(true) : setIsActive(false);

    window.addEventListener("scroll", activateArrowButton);
    return function cleanup() {
      window.removeEventListener("scroll", activateArrowButton);
    };
  }, [isActive]);

  useEffect(() => {
    const TOKEN = getItem("id_token");
    return TOKEN ? setAuth(true) : setAuth(false);
  });

  useEffect(() => {
    googleAuthInit();
  }, []);

  return (
    <>
      {isActive && <UpButton />}
      {!isCookie && <CoockieModal />}
      <Routes />
      <GoogleAnatytics />
    </>
  );
};

const mapState = (state) => {
  return {
    Auth: authSelector(state),
    isCookie: isCookieSelector(state),
  };
};

const mapDispatch = {
  setAuth: (prop) => set_auth(prop),
  setIsCookie: (prop) => set_is_cookie(prop),
};

export default connect(mapState, mapDispatch)(App);
