import React from "react"
import "./LittleSpinner.scss"

const LittleSpinner = () => {
    return(
        <div className="littleSpinner">
            <div className="littleSpinner-circle"></div>
        </div>
    )
}

export default LittleSpinner