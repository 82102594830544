import React, { useEffect } from "react"

//Material-Ui
import { Button } from "@mui/material"
import NotificationsIcon from '@mui/icons-material/Notifications';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';

//Components
import HeaderNotifications from "./HeaderNotifications/HeaderNotifications"

//Redux
import { connect } from "react-redux";
import { newNotifysListSelector } from "../../../redux/Notifications/notifications.selectors";
import { isNotificationsMenuSelector } from "../../../redux/Header/header.selectors";
import { set_is_notifications_menu } from "../../../redux/Header/header.actions";

const HeaderNotificationsMenu = ({ notifysList, isNotificationsMenu, setIsNotificationsMenu }) => {
    useEffect(() => {
        const checkMenuElement = async e => {
            if ( isNotificationsMenu === true ) {
                const isMenuElement = Array.from(e.target.classList).find( el => el.split("-")[0] === "notifications" || el.split("-")[0] === "notificationBtn"|| el.split("-")[0] === "MuiAvatar" || el.split("-")[0] === "MuiSvgIcon" )
                if ( isMenuElement || e.target.nodeName === "path" ) {
                    return
                }
                
                if ( !isMenuElement ) {
                    setIsNotificationsMenu(false)
                    return
                }
            }
        }

        document.addEventListener("mousedown", checkMenuElement)

        return () => document.removeEventListener("mousedown", checkMenuElement)
    }, [isNotificationsMenu])

    return(
        <li className="notifications-list-item" style={{ position: "relative" }}>
            <Button className="notificationBtn" onClick={() => setIsNotificationsMenu(!isNotificationsMenu)}>
                {isNotificationsMenu 
                    ? <NotificationsActiveIcon />
                    :  <NotificationsIcon />
                }
                {notifysList?.length > 0 && <div className="notificationBtn-notifysCounter">{notifysList.length}</div>}
            </Button>
            {isNotificationsMenu && <HeaderNotifications />}
        </li>
    )
}

const mapState = state => {
    return {
        notifysList: newNotifysListSelector(state),
        isNotificationsMenu: isNotificationsMenuSelector(state)
    }
}

const mapDispatch = {
    setIsNotificationsMenu: prop => set_is_notifications_menu(prop),
}

export default connect(mapState, mapDispatch)(HeaderNotificationsMenu)