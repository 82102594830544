//React
import React, { lazy, Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";

//Redux
import { connect } from "react-redux";
import { authSelector } from "../redux/Auth/auth.selectors";

//Components
import Spinner from "../components/ModalsItems/Spinner/Spinner";
import HighOrderPageGenerator from "../components/HighOrderPageGenerator/HighOrderPageGenerator";

const Routes = ({ Auth }) => {
  const HomePage = lazy(() => import("../components/Pages/NewHomePage"));
  const AboutUsPage = lazy(() =>
    import("../components/Pages/AboutUsPage/AboutUsPage")
  );
  const ContactUsPage = lazy(() =>
    import("../components/Pages/ContactUsPage/ContactUsPage")
  );
  const FAQPage = lazy(() => import("../components/Pages/FAQPage/FAQPage"));
  const LoginPage = lazy(() =>
    import("../components/Pages/LoginPage/LoginPage")
  );
  const MemorialsPage = lazy(() =>
    import("../components/Pages/MemorialsPage/MemorialsPage")
  );
  const PartnershipPage = lazy(() =>
    import("../components/Pages/PartnershipPage/PartnershipPage")
  );
  const PricingPage = lazy(() =>
    import("../components/Pages/PricingPage/PricingPage")
  );
  const PrivacyPolicyPage = lazy(() =>
    import("../components/Pages/PrivacyPolicyPage/PrivacyPolicyPage")
  );
  const SignupPage = lazy(() =>
    import("../components/Pages/SignupPage/SignupPage")
  );
  const TermsOfUse = lazy(() =>
    import("../components/Pages/SignupPage/TermsOfUse/TermsOfUse")
  );
  const ForgotPasswordPage = lazy(() =>
    import(
      "../components/Pages/LoginPage/ForgotPasswordPage/ForgotPasswordPage"
    )
  );
  const ProfilePage = lazy(() =>
    import("../components/Pages/ProfilePage/Profile")
  );
  const UserPersonalAreaPage = lazy(() =>
    import(
      "../components/Pages/ProfilePage/UserProfile/UserPersonalAreaPage/UserPersonalAreaPage"
    )
  );
  const OtherRouteWayPage = lazy(() =>
    import("../components/OtherRouteWayPage/OtherRouteWayPage")
  );
  const MirrorPage = lazy(() =>
    import("../components/Pages/MirrorPage/MirrorPage")
  );
  const DataProvider = lazy(() => import("../MemoMovie/hooks/useData"));
  const RegistrationSuccess = lazy(() =>
    import("../components/Pages/RegistrationSuccess/RegistrationSuccess")
  );
  const ConfirmEmail = lazy(() =>
    import("../components/Pages/ConfirmEmail/ConfirmEmail")
  );
  const PageConstruction = lazy(() =>
    import("../components/Pages/PageConstruction/PageConstruction")
  );
  const MemoMovie = lazy(() => import("../components/Pages/MemoMovie"));
  const MemoNFT = lazy(() => import("../components/Pages/MemoNFT/MemoNFT"));

  return (
    <Suspense fallback={<Spinner isBackground />}>
      <HighOrderPageGenerator>
        <Switch>
          <Route path="/" exact component={HomePage} />
          <Route path="/memopages" component={MemorialsPage} />
          {/* <Route path="/memomovie-advertising" component={MemoMovie} /> */}
          <Route path="/memonft" component={MemoNFT} />
          <Route path="/faq" component={FAQPage} />
          <Route path="/about_us" component={AboutUsPage} />
          <Route path="/contact_us" component={ContactUsPage} />
          <Route path="/log_in" component={LoginPage} />
          <Route path="/sign_up" component={SignupPage} />
          <Route path="/confirm_email" component={ConfirmEmail} />
          <Route path="/reg-succes" component={RegistrationSuccess} />
          <Route path="/forgot_password" component={ForgotPasswordPage} />
          <Route path="/terms_of_use" component={TermsOfUse} />
          <Route path="/privacy-policy" component={PrivacyPolicyPage} />
          <Route path="/pricing" component={PricingPage} />
          <Route path="/partnership" component={HomePage} />
          <Route path="/:id/personal_area" component={UserPersonalAreaPage} />
          <Route path="/memomovie/:id">
            <Route path="/page-construction" component={PageConstruction} />
            <DataProvider>
              <MirrorPage />
            </DataProvider>
          </Route>
          {Auth && <Route path="/profile" component={ProfilePage} />}
          <Route path="/:name" component={OtherRouteWayPage} />
        </Switch>
      </HighOrderPageGenerator>
    </Suspense>
  );
};

const mapState = (state) => {
  return {
    Auth: authSelector(state),
  };
};

export default connect(mapState)(Routes);
