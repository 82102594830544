import React from "react"
import { withRouter } from "react-router-dom"
import { IS_PRODUCTION } from "../../constants"

class GoogleAnalytics extends React.Component {
    componentDidUpdate({ location, history }) {
        if ( location.pathname === this.props.location.pathname ) {
            return
        }

        const gtag = window.gtag

        if ( 
            IS_PRODUCTION &&
            history.action === "PUSH" &&
            typeof(gtag) === "function"
        ) {
            gtag("config", "UA-8800913-1", {
                "page_title": document.title,
                "page_location": window.location.href,
                "page_path": location.pathname
            })
        }
    }

    render() {
        return null
    }
}

export default withRouter(GoogleAnalytics)