export const SET_USER_PERSONAL_DATA = "PERSONAL_AREA/SET_USER_PERSONAL_AREA"
export const SET_PERSONAL_AREA_ID = "PERSONAL_AREA/SET_PERSONAL_AREA_ID"

export const set_user_personal_data = data => {
    return {
        type: SET_USER_PERSONAL_DATA,
        payload: {
            data,
        }
    }
}

export const set_personal_area_id = id => {
    return {
        type: SET_PERSONAL_AREA_ID,
        payload: {
            id
        }
    }
}