export const useLocalStorages = () => {

    const getItem = type => {
        return JSON.parse(localStorage.getItem(type))
    }

    const setItem = ( type, item ) => {
        return localStorage.setItem( type, JSON.stringify(item) )
    }

    const removeItem = type => {
        return localStorage.removeItem(type)
    }

    const clearLocalStorage = () => {
        return localStorage.clear()
    }

    return {
        getItem,
        setItem,
        removeItem,
        clearLocalStorage
    }
    
}