import React, { useEffect } from "react"
import "./WarrningModal.scss"
//img
import BlackCloseImg from "../../../assets/images/black_close_img.png"
import WarrningImg from "../../../assets/images/warrning_img.png"

//Redux
import { connect } from "react-redux"
import { warrningTextSelector } from "../../../redux/MessageToModal/messageToModal.selectors"
import { show_warrning_modal } from "../../../redux/Modals/modals.actions"

const WarrningModal = ({ warrningText, showWarrningModal }) => {

    useEffect(() => {
        const onClick = e => {
            if ( e.keyCode === 27 ) {
                showWarrningModal(false)
            }
        }
        document.addEventListener("keydown", onClick)

        return () => document.removeEventListener("keydown", onClick)
    }, [])
    
    return(
        <div 
            className="warrningModal">
            <div className="warrningModal-close" onClick={ () => showWarrningModal(false) }>
                <img src={BlackCloseImg} alt="Close" />
            </div>
            <div className="warrningModal-container">
                <div className="warrningModal-img">
                    <img className="warrningModal-img-src" src={WarrningImg} alt="Warning Img" />
                </div>
                <div className="warrningModal-text">{warrningText}</div>
            </div>
        </div>
    )
}

const mapState = state => {
    return {
        warrningText: warrningTextSelector(state),
    }
}

const mapDispatch = {
    showWarrningModal: prop => show_warrning_modal(prop),
}

export default connect(mapState, mapDispatch)(WarrningModal)