import { SET_SETTINGS_EMAIL, SET_SETTINGS_NAME, SET_SETTINGS_SURNAME, SET_SETTINGS_COUNTRY, LOAD_IMAGE, CHANGE_USER_AVATAR, CHANGE_CROPPER_IMG, SET_IS_AVATAR_SAVED } from "./settings.actions";

const initialState = {
    email: "",
    name: "",
    surname: "",
    country: "",
    data: {},
    loadedImg: "",
    userAvatar: "",
    cropperImg: "",
    isAvatarSaved: false,
}

export const settingsReducer = ( state = initialState, action ) => {
    switch(action.type) {
        case SET_SETTINGS_EMAIL:
            return {
                ...state,
                email: action.payload.email
            }
        case SET_SETTINGS_NAME: 
            return {
                ...state,
                name: action.payload.name
            }
        case SET_SETTINGS_SURNAME:
            return {
                ...state,
                surname: action.payload.surname
            }
        case SET_SETTINGS_COUNTRY:
            return {
                ...state,
                country: action.payload.country
            }
        case LOAD_IMAGE:
            return {
                ...state,
                loadedImg: action.payload.img,
            }
        case CHANGE_USER_AVATAR:
            return {
                ...state,
                userAvatar: action.payload.img,
            }
        case CHANGE_CROPPER_IMG:
            return {
                ...state,
                cropperImg: action.payload.img,
            }
        case SET_IS_AVATAR_SAVED:
            return {
                ...state,
                isAvatarSaved: action.payload.prop,
            }
        default: return state
    }
}