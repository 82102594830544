//Hooks
import { getUserData, getNewNotifysList } from "../../../hooks/useApi"

//Redux
import { useDispatch, useSelector } from "react-redux"
import { set_user_data } from "../../../redux/Profile/UserData/userdata.actions"
import { set_new_notifys_list } from "../../../redux/Notifications/notifications.actions"
import { change_user_avatar, set_settings_country, set_settings_email, set_settings_name, set_settings_surname } from "../../../redux/Profile/Settings/settings.actions"

export const useHomeHeaderApi = () => {
    const dispatch = useDispatch()

    const isAuth = useSelector(state => state.auth.isAuth)

    const setUserData = id => {
        return getUserData(id)
            .then( res => {
                dispatch( set_user_data(res.data) )
                dispatch( change_user_avatar(res.data.avatar) )
                dispatch( set_settings_email( res.data.email ) )
                dispatch( set_settings_name(res.data.name) )
                dispatch( set_settings_surname( res.data.surname) )
                dispatch( set_settings_country( res.data.country) )
            }).catch( err => dispatch(set_user_data(null)))
    }

    const setNotifysList = async () => {
        return await getNewNotifysList()
            .then( res => {
                if ( res.status === 200 ) {
                    dispatch(set_new_notifys_list(res.data))
                }
            }).catch( err => dispatch(set_new_notifys_list(null)))
    }

    return {
        setUserData,
        setNotifysList,
    }
}