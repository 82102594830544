export const SET_MEMORIAL_ID = "EDIT_MEMORIAL/SET_MEMORIAL_ID";
export const SET_EXCERPT = "EDIT_MEMORIAL/SET_EXCERPT";
export const SET_SHORT_DESCRIPTION = "EDIT_MEMORIAL/SET_SHORT_DESCRIPTION";
export const SET_FULL_DESCRIPTION = "EDIT_MEMORIAL/SET_FULL_DESCRIPTION";
export const SET_GEOTAG = "EDIT_MEMORIAL/SET_GEOTAG";
export const SET_MEMORIAL_IMAGES = "EDIT/SET_MEMORIAL_IMAGES";
export const SET_POSTER_CROPPER_IMG = "EDIT/SET_POSTER_CROPPER_IMG";
export const SET_CARD_POSTER_CROPPER_IMG = "EDIT/SET_CARD_POSTER_CROPPER_IMG";
export const SET_POSTER_IMG = "EDIT/SET_POSTER_IMG";
export const SET_CARD_POSTER_IMG = "EDIT/SET_CARD_POSTER_IMG";
export const SET_MEMORIAL_STATUS = "EDIT/SET_MEMORIAL_STATUS";
export const SET_MEMORIAL_TARIF = "EDIT/SET_MEMORIAL_TARIF";
export const SET_MEMORIAL_BACK_IMAGES_LIST =
  "EDIT/SET_MEMORIAL_BACK_IMAGES_LIST";
export const SET_MEMORIAL_BACK_IMAGE = "EDIT/SET_MEMORIAL_BACK_IMAGE";
export const SET_MEMORIAL_ADDRESS = "EDIT/SET_MEMORIAL_ADDRESS";
export const SET_POSTER_IMAGE_ID = "EDIT/SET_POSTER_IMAGE_ID";
export const SET_IS_POSTER_SAVED = "EDIT/SET_IS_POSTER_SAVED";
export const SET_IS_CARD_POSTER_SAVED = "EDIT/SET_IS_CARD_POSTER_SAVED";
export const SET_LOADED_IMAGES_FILES = "EDIT/SET_LOADED_IMAGES_FILES";
export const SET_IS_STATUS_CHANGED = "EDIT/SET_IS_STATUS_CHANGED";
export const SET_LOADED_IMAGES = "EDIT/SET_LOADED_IMAGES";
export const SET_CAN_RELOAD_MEMORIAL_DATA = "EDIT/SET_CAN_RELOAD_MEMORIAL_DATA";
export const SET_IMAGES_VALIDATOR_ARRAY = "EDIT/SET_IMAGES_VALIDATOR_ARRAY";
export const SET_IS_IMAGES_SENDED = "EDIT/SET_IS_IMAGES_SENDED";
export const SET_PAYMENT_VALIDATION_VALUE = "EDIT/SET_PAYMENT_VALIDATION_VALUE";
export const SET_IMAGES_STRINGS = "EDIT/SET_IMAGES_STRINGS";
export const SET_IS_UPDATED = "EDIT/SET_IS_UPDATED";
export const SET_MEMORIAL_COOWNER_ID = "EDIT/SET_MEMORIAL_COOWNER_ID";
export const SET_LOADED_FILE_INDEX = "EDIT/SET_LOADED_FILE_INDEX";
export const SET_EVENT_NAME = "EDIT/SET_EVENT_NAME";
export const SET_EVENT_DATE = "EDIT/SET_EVENT_DATE";

export const set_memorial_id = (id) => {
  return {
    type: SET_MEMORIAL_ID,
    payload: {
      id,
    },
  };
};

export const set_excerpt = (text) => {
  return {
    type: SET_EXCERPT,
    payload: {
      text,
    },
  };
};
export const set_short_description = (text) => {
  return {
    type: SET_SHORT_DESCRIPTION,
    payload: {
      text,
    },
  };
};

export const set_full_description = (text) => {
  return {
    type: SET_FULL_DESCRIPTION,
    payload: {
      text,
    },
  };
};

export const set_geotag = (geotag) => {
  return {
    type: SET_GEOTAG,
    payload: {
      geotag,
    },
  };
};

export const set_memorial_images = (imagesData) => {
  return {
    type: SET_MEMORIAL_IMAGES,
    payload: {
      imagesData,
    },
  };
};

export const set_poster_cropper_img = (img) => {
  return {
    type: SET_POSTER_CROPPER_IMG,
    payload: {
      img,
    },
  };
};

export const set_card_poster_cropper_img = (img) => {
  return {
    type: SET_CARD_POSTER_CROPPER_IMG,
    payload: {
      img,
    },
  };
};

export const set_poster_img = (img) => {
  return {
    type: SET_POSTER_IMG,
    payload: {
      img,
    },
  };
};

export const set_card_poster_img = (img) => {
  return {
    type: SET_CARD_POSTER_IMG,
    payload: {
      img,
    },
  };
};

export const set_memorial_status = (status) => {
  return {
    type: SET_MEMORIAL_STATUS,
    payload: {
      status,
    },
  };
};

export const set_memorial_tarif = (tarif) => {
  return {
    type: SET_MEMORIAL_TARIF,
    payload: {
      tarif,
    },
  };
};

export const set_memorial_back_images_list = (list) => {
  return {
    type: SET_MEMORIAL_BACK_IMAGES_LIST,
    payload: {
      list,
    },
  };
};

export const set_memorial_back_image = (img) => {
  return {
    type: SET_MEMORIAL_BACK_IMAGE,
    payload: {
      img,
    },
  };
};

export const set_memorial_address = (address) => {
  return {
    type: SET_MEMORIAL_ADDRESS,
    payload: {
      address,
    },
  };
};

export const set_poster_image_id = (id) => {
  return {
    type: SET_POSTER_IMAGE_ID,
    payload: {
      id,
    },
  };
};

export const set_is_poster_saved = (prop) => {
  return {
    type: SET_IS_POSTER_SAVED,
    payload: {
      prop,
    },
  };
};

export const set_is_card_poster_saved = (prop) => {
  return {
    type: SET_IS_CARD_POSTER_SAVED,
    payload: {
      prop,
    },
  };
};

export const set_loaded_images_files = (list) => {
  return {
    type: SET_LOADED_IMAGES_FILES,
    payload: {
      list,
    },
  };
};

export const set_loaded_images = (list) => {
  return {
    type: SET_LOADED_IMAGES,
    payload: {
      list,
    },
  };
};

export const set_is_status_changed = (prop) => {
  return {
    type: SET_IS_STATUS_CHANGED,
    payload: {
      prop,
    },
  };
};

export const set_can_reload_memorial_data = (prop) => {
  return {
    type: SET_CAN_RELOAD_MEMORIAL_DATA,
    payload: {
      prop,
    },
  };
};

export const set_images_validator_array = (array) => {
  return {
    type: SET_IMAGES_VALIDATOR_ARRAY,
    payload: {
      array,
    },
  };
};

export const set_is_images_sended = (prop) => {
  return {
    type: SET_IS_IMAGES_SENDED,
    payload: {
      prop,
    },
  };
};

export const set_payment_validation_value = (value) => {
  return {
    type: SET_PAYMENT_VALIDATION_VALUE,
    payload: {
      value,
    },
  };
};

export const set_images_strings = (number) => {
  return {
    type: SET_IMAGES_STRINGS,
    payload: {
      number,
    },
  };
};

export const set_is_updated = (prop) => {
  return {
    type: SET_IS_UPDATED,
    payload: {
      prop,
    },
  };
};

export const set_memorial_coowner_id = (id) => {
  return {
    type: SET_MEMORIAL_COOWNER_ID,
    payload: {
      id,
    },
  };
};

export const set_loaded_file_index = (index) => {
  return {
    type: SET_LOADED_FILE_INDEX,
    payload: {
      index,
    },
  };
};

export const set_event_name = (eventName) => {
  return {
    type: SET_EVENT_NAME,
    payload: {
      eventName,
    },
  };
};

export const set_event_date = (eventDate) => {
  return {
    type: SET_EVENT_DATE,
    payload: {
      eventDate,
    },
  };
};
