import React, { useState, useEffect } from "react";
import { NAVBAR_LINKS } from "../../../constants";
import Button from "../../Common/Button";
import SelectInput from "../../Common/SelectInput";
import Logo from "./components/Logo";
import Navlink from "./components/Navlink";
import person from "../../../assets/images/person-icon.svg";
import globe from "../../../assets/images/globe-icon.svg";
import burger from "../../../assets/images/burger-icon.svg";
import close from "../../../assets/images/close-cross.svg";

import styles from "./NewHeader.module.scss";

//Redux
import { connect } from "react-redux";
import {
  authSelector,
  userIdSelector,
} from "../../../redux/Auth/auth.selectors";
import { userDataSelector } from "../../../redux/Profile/UserData/userdata.selectors";
import HeaderProfileMenu from "./../../Header/HeaderProfileMenu/HeaderProfileMenu";
import classNames from "classnames";

const LANGUAGE_SETTINGS = ["ES", "Eng"];

function NewHeader({ Auth, userData, userId }) {
  const [language, setLanguage] = useState("Eng");
  const [isMobileMenuVisible, setIsMobileMenuVisible] = useState(false);

  useEffect(() => {
    if (userId > 0) {
      localStorage.setItem("userId", userId);
    }
  });

  return (
    <div className={styles.newHeaderContainer}>
      <Logo />
      <div className={styles.navlinksContainer}>
        {NAVBAR_LINKS.map((link) => {
          return <Navlink key={link.text} href={link.href} text={link.text} />;
        })}
      </div>
      {/* <div className={styles.selectContainer}>
        <img src={globe} alt="globe" />
        <SelectInput
          initialValue={language}
          options={LANGUAGE_SETTINGS}
          onChange={(option) => setLanguage(option)}
        />
      </div> */}
      <div className={styles.buttonAndBurger}>
        {Auth ? (
          <div className={styles.burgerIconMob}>
            <HeaderProfileMenu />
          </div>
        ) : (
          <div className={styles.mainBlock}>
            <Button
              text={
                <span className={styles.buttonText}>
                  <img src={person} alt="icon" />
                  <span>Sign in</span>
                </span>
              }
              type="link"
              href={Auth ? "/profile/new" : { pathname: "/log_in" }}
              color="light"
              isRound
            />
          </div>
        )}

        {isMobileMenuVisible ? (
          <img
            src={close}
            alt="close"
            className={classNames(styles.burgerIconAuth, {
              [styles.burgerIconNoAuth]: !Auth,
            })}
            onClick={() => setIsMobileMenuVisible(false)}
          />
        ) : (
          <img
            src={burger}
            className={classNames(styles.burgerIconAuth, {
              [styles.burgerIconNoAuth]: !Auth,
            })}
            alt="burger icon"
            onClick={() => setIsMobileMenuVisible(true)}
          />
        )}
      </div>
      {isMobileMenuVisible && (
        <>
          <div
            className={styles.mobileMenuWrapper}
            onClick={() => setIsMobileMenuVisible(false)}
          />
          <div className={styles.mobileMenu}>
            {/* <div className={styles.mobileSelectContainer}>
              <img src={globe} alt="globe" />
              <SelectInput
                initialValue={language}
                options={LANGUAGE_SETTINGS}
                onChange={(option) => setLanguage(option)}
              />
            </div> */}
            <div className={styles.mobileNavlinksContainer}>
              {NAVBAR_LINKS.map((link) => {
                return (
                  <Navlink
                    key={link.text}
                    href={link.href}
                    text={link.text}
                    onClick={() => setIsMobileMenuVisible(false)}
                  />
                );
              })}
            </div>
          </div>
        </>
      )}
    </div>
  );
}

const mapState = (state) => {
  return {
    Auth: authSelector(state),
    userId: userIdSelector(state),
    userData: userDataSelector(state),
  };
};

export default connect(mapState)(NewHeader);
