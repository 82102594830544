import { SET_IS_NOTIFICATIONS_MENU, SET_IS_PROFILE_MENU } from "./header.actions"

const initialState = {
    isProfileMenu: false,
    isNotificationsMenu: false,
}

export const headerReducer = ( state = initialState, action ) => {
    switch( action.type ) {
        case SET_IS_PROFILE_MENU:
            return {
                ...state,
                isProfileMenu: action.payload.prop,
            }
        case SET_IS_NOTIFICATIONS_MENU:
            return {
                ...state,
                isNotificationsMenu: action.payload.prop,
            }
        default: return state
    }
}