export const SET_MIRROR_DATA = "MIRROR/SET_MIRROR_DATA"
export const SET_MEMORIAL_DATA = "MEMORIAL/SET_MEMORIAL_DATA"
export const SET_LAST_SIX_MEMORIALS = "MEMORIALS/SET_LAST_SIX_MEMORIALS"
export const SET_IS_USER_INVITED_TO_MEMOMOVIE = "MIRROR/SET_IS_USER_INVITED_TO_MEMOMOVIE"
export const SET_IS_UNPAID_MEMOMOVIE = "MIRROR/SET_IS_UNPAID_MEMOMOVIE"
export const SET_IS_MM_PHOTH_CHANGED = "MIRROR/SET_IS_MM_PHOTH_CHANGED"

export const set_mirror_data = data => {
    return {
        type: SET_MIRROR_DATA,
        paylaod: {
            data,
        }
    }
}

export const set_memorial_data = data => {
    return {
        type: SET_MEMORIAL_DATA,
        payload: {
            data,
        }
    }
}

export const set_last_six_memorials = list => {
    return {
        type: SET_LAST_SIX_MEMORIALS,
        payload: {
            list,
        }
    }
}

export const set_is_user_invited_to_memomovie = prop => {
    return {
        type: SET_IS_USER_INVITED_TO_MEMOMOVIE,
        payload: {
            prop,
        }
    }
}

export const set_is_unpaid_memomovie = prop => {
    return {
        type: SET_IS_UNPAID_MEMOMOVIE,
        payload: {
            prop,
        }
    }
}

export const set_is_mm_photo_changed = prop => {
    return {
        type: SET_IS_MM_PHOTH_CHANGED,
        payload: {
            prop
        }
    }
}