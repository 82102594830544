export const SET_NEW_PASSWORD = "PASSWORD/SET_NEW_PASSWORD"
export const CONFIRM_NEW_PASSWORD = "PASSWORD/CONFIRM_NEW_PASSWORD"

export const set_new_password = password => {
    return {
        type: SET_NEW_PASSWORD,
        payload: {
            password,
        }
    }
}

export const confirm_new_password = password => {
    return {
        type: CONFIRM_NEW_PASSWORD,
        payload: {
            password,
        }
    }
}