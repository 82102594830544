import { SET_FIRST_STRING_IMAGES_LIST, SET_IS_NONECONTAINER_PAGE, SET_IS_PRIVAT_PAGE, SET_IS_UNPAYED_PAGE, SET_MEMORIAL_CONFIG_DATA, SET_MEMORIAL_CONFIG_FRIENDS, SET_MEMORIAL_CONFIG_IMAGES, SET_MEMORIAL_CONFIG_TRIBUTES, SET_SECOND_STRING_IMAGES_LIST } from "./memorialpage.actions"

const initialState = {
    isNoneContainerPage: false,
    isUnpayedPage: true,
    isPrivatPage: false,
    config:{
        data: null,
        images: null,
        friends: null,
        tributes: null,
    },
    firstStringImagesList: null,
    secondStringImagesList: null,
}

export const memorialPageReducer = ( state = initialState, action ) => {
    switch( action.type ) {
        case SET_IS_UNPAYED_PAGE:
            if(typeof(action.payload.prop) !== "boolean") return state
            return {
                ...state,
                isUnpayedPage: action.payload.prop
            }

        case SET_IS_PRIVAT_PAGE:
            if(typeof(action.payload.prop) !== "boolean") return state
            return {
                ...state,
                isPrivatPage: action.payload.prop
            }

        case SET_IS_NONECONTAINER_PAGE:
            return {
                ...state,
                isNoneContainerPage: action.payload.prop,
            }

        case SET_MEMORIAL_CONFIG_DATA:
            return {
                ...state,
                config: {
                    ...state.config,
                    data: action.payload.data
                }   
            }

            case SET_MEMORIAL_CONFIG_IMAGES:
                return {
                    ...state,
                    config: {
                        ...state.config,
                        images: action.payload.images
                    }   
                }

            case SET_MEMORIAL_CONFIG_FRIENDS:
                return {
                    ...state,
                    config: {
                        ...state.config,
                        friends: action.payload.friends
                    }   
                }

            case SET_MEMORIAL_CONFIG_TRIBUTES:
                return {
                    ...state,
                    config: {
                        ...state.config,
                        tributes: action.payload.tributes
                    }   
                }
            
            case SET_FIRST_STRING_IMAGES_LIST:
                return {
                    ...state,
                    firstStringImagesList: action.payload.list,
                }

            case SET_SECOND_STRING_IMAGES_LIST:
                return {
                    ...state,
                    secondStringImagesList: action.payload.list,
                }

            default: return state
    }
}