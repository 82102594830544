import React, { useEffect } from "react"
import "./SuccessModal.scss"

//Img
import BlackCloseImg from "../../../assets/images/black_close_img.png"
import SuccessImg from "../../../assets/images/success_img.png"

//Redux
import { connect } from "react-redux"
import { successTextSelector } from "../../../redux/MessageToModal/messageToModal.selectors"
import { show_success_modal } from "../../../redux/Modals/modals.actions"
import { set_success_text } from "../../../redux/MessageToModal/messageToModal.actions"

const SuccessModal = ({ successText, setSuccessText, showModal }) => {
    useEffect(() => {
        const onClick = e => {
            if ( e.keyCode === 27 ) {
                showModal(false)
            }
        }
        document.addEventListener("keydown", onClick)

        return () => document.removeEventListener("keydown", onClick)
    }, [])

    return(
        <div className="successModal">
            <div className="successModal-close" onClick={ () => showModal(false) }>
                <img className="successModal-close-img" src={BlackCloseImg} alt="Close" />
            </div>
            <div className="successModal-container">
                <div className="successModal-img">
                    <img src={SuccessImg} alt="Success Img" />
                </div>
                <div className="successModal-text">{successText}</div>
            </div>
        </div>
    )
}

const mapState = state => {
    return {
        successText: successTextSelector(state),
    }
}

const mapDispatch = {
    showModal: prop => show_success_modal(prop),
    setSuccessText: text => set_success_text(text),
}

export default connect(mapState, mapDispatch)(SuccessModal)