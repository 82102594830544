import { SET_CURRENT_LAYOUT, SET_CURRENT_LAYOUT_FILTER_TYPE } from "./currentlayout.actions";

const initialState = {
    currentLayout: 1,
    filterType: "all",
}

export const currentLayoutReducer = ( state = initialState, action ) => {
    switch( action.type ) {
        case SET_CURRENT_LAYOUT:
            return {
                ...state,
                currentLayout: action.payload.layout
            }
        case SET_CURRENT_LAYOUT_FILTER_TYPE:
            return {
                ...state,
                filterType: action.payload.type
            }
        default: return state
    }
}