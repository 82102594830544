import React from "react";
import { Link } from "react-router-dom";

import NewLogo from "../../../../../assets/images/newLogo.svg";
import mobileLogo from "../../../../../assets/images/mobile-logo.svg";

import styles from "./Logo.module.scss";
import classNames from "classnames";

function Logo({ small }) {
  return (
    <Link
      to="/"
      className={classNames(styles.logoContainer, {
        [styles.logoSmall]: small,
      })}
    >
      <img src={NewLogo} className={styles.image} />
      <img src={NewLogo} className={styles.mobileImage} />
    </Link>
  );
}

export default Logo;
